<template>
    <div class="upload-widget">
        <van-uploader v-if="!file && !modelValue" :after-read="afterRead" :accept="type">
            <div class="upload-selecter">
                Select file
            </div>
        </van-uploader>

        <div v-else-if="file" class="upload-preview">
            <img v-if="selectedFileType == 'image'" :src="fileContent" alt="">
            <div v-else class="fileName">
                {{ file.name }}
            </div>

            <a class="btn-delete" @click.prevent="deleteFile()">
                <van-icon name="cross" color=" #fff" />
            </a>
        </div>

        <van-progress v-if="file && !modelValue" :percentage="percent" color="#0E142A" style="margin: 10px 0;" />
    </div>
</template>
<script>
import axios from 'axios'
import { API_BASE_URL, CREATE_UPLOAD_URL, ASSETS_BASE_URL } from '@/store/variables'
export default {
    name: "uploader",
    emits: ['onFileUploaded'],
    props: {
        type: {
            type: String,
            default: "image/*",
            required: true
        },
        modelValue: {
            type: String,
            default: ""
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            file: null,
            fileContent: null,
            selectedFileType: "",
            percent: 0,
            fileUploadedData: null
        }
    },
    watch: {
        modelValue: function (val) {
            let vm = this;
            if (val) {
                vm.file = {
                    name: val
                };
                vm.fileUploadedData = {
                    fileName: val
                };
                vm.selectedFileType = vm.type.split('/')[0];
                vm.fileContent = ASSETS_BASE_URL + val;
            }
        }
    },
    mounted() {
        let vm = this;
        if (vm.modelValue) {
            vm.file = {
                name: vm.modelValue
            };
            vm.fileUploadedData = {
                fileName: vm.modelValue
            };
            vm.selectedFileType = vm.type.split('/')[0];
            vm.fileContent = ASSETS_BASE_URL + vm.modelValue;
        }
    },
    methods: {
        afterRead(file) {
            let vm = this;
            vm.fileContent = file.content;
            vm.file = file.file;
            vm.selectedFileType = vm.file.type.split('/')[0];
            vm.uploadNow();
        },
        uploadNow() {
            let vm = this;
            this.percent = 0
            const form = new FormData()
            form.append('file', vm.file, vm.file.name);
            const config = {
                onUploadProgress: function (progressEvent) {
                    this.percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                }.bind(this),
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + vm.$store.getters.get_access_token
                },
            }
            axios
                .post(API_BASE_URL + CREATE_UPLOAD_URL, form, config)
                .then(respo => {
                    vm.fileUploadedData = respo.data.data;
                    vm.$emit('onFileUploaded', respo.data.data)
                    vm.$emit('update:modelValue', respo.data.data.fileName)
                })
                .catch(err => console.log(err))

        },
        deleteFile() {
            let vm = this;
            vm.$store
                .dispatch("DELETE_UPLOADED_FILE", vm.fileUploadedData)
                .then((response) => {
                    vm.file = null;
                    vm.fileContent = null;
                    vm.selectedFileType = "";
                    vm.percent = 0;
                    vm.fileUploadedData = null;
                    vm.$emit('onFileUploaded', '');
                    vm.$emit('update:modelValue', '')
                })

        }

    }
}
</script>
<style lang="scss">
.upload-widget {
    width: max-content;
    // height: 100%;
    position: relative;
    margin-bottom: 20px;

    .upload-selecter {
        height: 80px;
        font-size: 0.8rem;
        width: 250px;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #c8cfff;
        border: 1px dashed #557aff;
        border-radius: 5px;
    }

    .upload-preview {
        height: 80px;
        width: 250px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #c8cfff;
        border: 1px dashed #557aff;
        border-radius: 5px;
        position: relative;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }

        .fileName {
            height: 100%;
            width: 100%;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #c8cfff;
            border: 1px dashed #557aff;
            border-radius: 5px;
        }

        .btn-delete {
            position: absolute;
            top: -8px;
            right: -7px;
            background: #d60560;
            border: none;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
        }
    }
}
</style>
import { GET_ALL_PODCASTS,GET_ALL_TIMELINE_PODCASTS,CREATE_PODCAST,UPDATE_PODCAST,DELETE_PODCAST, GET_RECENT_PODCASTS } from "../actions/podcasts";
import { API_BASE_URL,GET_ALL_TIMELINE_PODCASTS_URL,GET_ALL_PODCASTS_URL,CREATE_PODCAST_URL,UPDATE_PODCAST_URL,DELETE_PODCAST_URL, GET_RECENT_PODCASTS_URL} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [GET_ALL_PODCASTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_PODCASTS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [GET_ALL_TIMELINE_PODCASTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_TIMELINE_PODCASTS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [GET_RECENT_PODCASTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_RECENT_PODCASTS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [CREATE_PODCAST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_PODCAST_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [UPDATE_PODCAST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + UPDATE_PODCAST_URL + payload.id,
            method: "PUT",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [DELETE_PODCAST]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_PODCAST_URL + payload,
            method: "DELETE",
            // data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
};
export default {
    mutations,
    actions,
    getters,
    state
};
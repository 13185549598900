import { createStore } from 'vuex';
import auth from './modules/auth';
import users from './modules/users';
import programs from './modules/programs';
import podcasts from './modules/podcasts';
import news from './modules/news';
import upload from "./modules/upload"
import contact from "./modules/contact"
import search from "./modules/search"
import site from "./modules/site"

export default createStore({
  state: {
    openSidebar: false,
    searchText: '',
    searchResults: null,
    accessToken: null
  },
  getters: {
    get_access_token(state) {
      if (state.accessToken) {
        return state.accessToken;
      }
      return 'No token';
    }
  },
  mutations: {
  },
  actions: {
    setAccessToken({ commit, state }, token) {
      state.accessToken = token;
    },
    removeAccessToken({ commit, state }) {
      state.accessToken = null;
    }
  }
  ,
  modules: {
    auth,
    users,
    programs,
    podcasts,
    news,
    upload,
    contact,
    search,
    site
  }
})

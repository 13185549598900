<template>
  <div class="content">
    <loader v-if="isLoading" />
    <div class="wholepodcast">
      <h2 class="heading">Podcast</h2>
      <div class="podcast" v-if="podcast">
        <div class="top">
          <div class="podcastImg">
            <img :src="assetsUrl + podcast.imageFileName" alt="">
          </div>
          <div class="description">
            <h3>{{ podcast.title }}</h3>
            <p>{{ podcast.description }}</p>
            <label class="time">{{ datetime(podcast.createdAt) }}</label>
            <div>
              <div class="share">
                <h4>SHARE:</h4>
                <div class="socialmedias">
                  <ShareNetwork network="facebook" :url="postcastLink + podcast.id" :title="podcast.title">
                    <div class="icon">
                      <img src="@/assets/svg/lfacebook.svg" alt="">
                    </div>
                  </ShareNetwork>
                  <ShareNetwork network="twitter" :url="postcastLink + podcast.id" :title="podcast.title">
                    <div class="icon">
                      <img src="@/assets/svg/ltwitter.svg" alt="">
                    </div>
                  </ShareNetwork>
                  <ShareNetwork network="whatsapp" :url="postcastLink + podcast.id" :title="podcast.title">
                    <div class="icon">
                      <img src="@/assets/svg/whatsapp.svg" alt="">
                    </div>
                  </ShareNetwork>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="playPodcast">
          <!-- <button class="playbutton" @click="playPod(this.$refs['audio' + podcast.id], podcast)">
            <img v-if="podcast.isPlaying" src="@/assets/svg/pause.svg" alt="">
            <img v-else src="@/assets/svg/play.svg" alt="">
          </button>
          <audio :ref="'audio' + podcast.id" :src="assetsUrl + podcast.audioFileName" hidden></audio> -->
          <vue-plyr class="plyr">
            <audio controls crossorigin playsinline>
              <source
                  :src="assetsUrl + podcast.audioFileName"
              />
            </audio>
          </vue-plyr>
        </div>
      </div>
      <div class="error404" v-else>
        <router-link to="/podcasts">GO TO PODCASTS</router-link>
        <img src="@/assets/svg/article.svg" draggable="false" alt="">
        <h4>Oops! Podcast not found</h4>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ASSETS_BASE_URL } from '@/store/variables'
import 'vue-plyr/dist/vue-plyr.css'
export default {
  name: 'Article',
  data() {
    return {
      assetsUrl: ASSETS_BASE_URL,
      postcastLink: 'https://cityradio.rw/podcasts/',
      isLoading: false,
      allpodcasts: [],
      comment: '',
      allcomments: [],
    }
  },
  computed: {
    podcast() {
      let check = this.allpodcasts.some(element => this.$route.params.id == element.id)
      if (check == true) {
        return this.allpodcasts.find(element => this.$route.params.id == element.id)
      } else {
        return false
      }
    },
  },
  methods: {
    datetime(time) {
      return time.split('T')[1].split(':')[0] + ":" + time.split('T')[1].split(':')[1] + ", " + time.split('T')[0]
    },
    playPod(audio,details) {
      if (audio.paused) {
        document.querySelectorAll('audio').forEach(aud => aud.pause())
        audio.play()
        details.isPlaying = true
      } else {
        document.querySelectorAll('audio').forEach(aud => aud.pause())
        audio.pause()
        details.isPlaying = false
      }
    }
  },
  mounted() {
    let vm = this
    vm.$getAllPodcasts()
  }
}
</script>
<style scoped lang="scss">
.content {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 40px 40px 50px 40px;

  @media (max-width: 1070px) {
    padding: 20px 20px 150px 20px;
  }
  @media (max-width: 500px) {
    padding: 20px 20px 200px 20px;
  }

  &::-webkit-scrollbar {
    width: 10px;

    @media (max-width: 480px) {
      width: 5px;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 20px;
    background: #0E142A;
  }
  .wholepodcast{
    .podcast{
      .top{
        display: flex;
        justify-content: center;
        column-gap: 70px;
        padding: 0 40px;
        margin: 20px 0;
        @media (max-width: 1070px) {
          padding: 0;
        }
        @media (max-width: 972px) {
          column-gap: 30px;
        }
        @media (max-width: 750px) {
          flex-direction: column;
          align-items: center;
          row-gap: 30px;
        }
        .podcastImg{
          width: 300px;
          height: 300px;
          flex-shrink: 0;
          position: relative;
          overflow: hidden;
          border-radius: 4px;
          @media (max-width: 500px) {
            height: 200px;
          }
          img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .description{
          width: 50%;
          @media (max-width: 972px) {
            width: 100%;
          }
          h3 {
            font-size: 1.2rem;
            width: fit-content;
            margin: 0 0 10px 0;
            @media (max-width: 630px) {
              font-size: 1rem;
            }
          }
          .time {
            font-size: 0.85rem;
            color: #646464;
            display: block;
            margin: 10px 0;
          }
          p{
            font-size: 0.9rem;
            line-height: 1.7rem;
            color: #0E142A;
          }
          .share {
            display: flex;
            column-gap: 20px;
            align-items: center;

            .socialmedias {
              display: flex;
              align-items: center;
              column-gap: 20px;

              .icon {
                margin: auto;
                position: relative;
                width: 25px;
                height: 25px;
                cursor: pointer;

                &:not(:last-child) {
                  margin-left: 10px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  top: 50%;
                  left: 50%;
                  position: absolute;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
      .playPodcast{ 
        display: flex;
        justify-content: center;
        .playbutton {
          all: unset;
          position: relative;
          width: 40px;
          height: 40px;
          cursor: pointer;
          border-radius: 100%;
          background: #0E142A;
          flex-shrink: 0;

          img {
            position: absolute;
            width: 50%;
            height: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  .error404 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    img {
      margin: 30px 0;
      width: 400px;

      @media (max-width: 550px) {
        width: 80%;
      }
    }

    a {
      padding: 4px 15px;
      text-decoration: none;
      border-radius: 5px;
      font-size: 0.9rem;
      border: 1px solid #E0291B;
      background: #E0291B;
      color: #fff;
      transition: .6s;

      &:hover {
        background: #fff;
        color: #E0291B;
      }
    }
  }
}
</style>
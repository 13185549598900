import { 
        GET_ALL_CATEGORIES,
        GET_SINGLE_CATEGORY,
        CREATE_CATEGORY,
        UPDATE_CATEGORY,
        DELETE_CATEGORY,
        
        GET_ALL_ARTICLES,
        CREATE_ARTICLE,
        UPDATE_ARTICLE,
        DELETE_ARTICLE,

        GET_ALL_COMMENTS,
        CREATE_COMMENT,
        UPDATE_COMMENT,
        DELETE_COMMENT,
        GET_RECENT_ARTICLES,

} from "../actions/news";
import { 
    API_BASE_URL,
    GET_ALL_CATEGORIES_URL,
    GET_SINGLE_CATEGORY_URL,
    CREATE_CATEGORY_URL,
    UPDATE_CATEGORY_URL,
    DELETE_CATEGORY_URL,
    
    GET_ALL_ARTICLES_URL,
    CREATE_ARTICLE_URL,
    UPDATE_ARTICLE_URL,
    DELETE_ARTICLE_URL,

    GET_ALL_COMMENTS_URL,
    CREATE_COMMENT_URL,
    UPDATE_COMMENT_URL,
    DELETE_COMMENT_URL,
    GET_RECENT_ARTICLES_URL
} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [GET_ALL_CATEGORIES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_CATEGORIES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [GET_SINGLE_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_SINGLE_CATEGORY_URL + payload,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [GET_RECENT_ARTICLES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_RECENT_ARTICLES_URL + payload,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [CREATE_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_CATEGORY_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [UPDATE_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + UPDATE_CATEGORY_URL + payload.id,
            method: "PUT",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [DELETE_CATEGORY]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_CATEGORY_URL + payload,
            method: "DELETE",
            // data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [GET_ALL_ARTICLES]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_ARTICLES_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [CREATE_ARTICLE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_ARTICLE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [UPDATE_ARTICLE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + UPDATE_ARTICLE_URL + payload.id,
            method: "PUT",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [DELETE_ARTICLE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_ARTICLE_URL + payload,
            method: "DELETE",
            // data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [GET_ALL_COMMENTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_COMMENTS_URL + payload.id,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [CREATE_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_COMMENT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [UPDATE_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + UPDATE_COMMENT_URL + payload.id,
            method: "PUT",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [DELETE_COMMENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_COMMENT_URL + payload,
            method: "DELETE",
            // data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
};
export default {
    mutations,
    actions,
    getters,
    state
};
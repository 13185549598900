<template>
    <div class="chat-message-input">
        <div class="chat-input-widget">
            <input type="text" class="chat-input" placeholder="Type your message" />
        </div>
        <button class="btn-send">
            <img src="@/assets/images/icons/chat-send.svg">
        </button>
    </div>
</template>
  
<script>
export default {
    name: 'chat-message-input',
    props: {},
    methods: {

    },
}
</script>

<style scoped lang="scss">
.chat-message-input {
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    border-top: 1px solid #cecece;

    .chat-input-widget {
        width: calc(100% - 60px);
        display: flex;
        padding-left: 10px;

        .chat-input {
            width: 100%;
            height: 38px;
            padding: 0px 10px;
        }
    }

    .btn-send {
        width: 60px;
        display: flex;
        justify-content: center;

        img {
            width: 40px;
        }
    }

}
</style>

<template>
    <div class="fixed">
        <button class="playbutton" @click="playPod()">
            <img v-if="play" src="@/assets/svg/play.svg" alt="">
            <img v-else src="@/assets/svg/pause.svg" alt="">
        </button>
        <div class="left">
            <audio id="radioPlayer" playsinline="" autoplay="" hidden @canplay="visualizer()">
                <source src="https://stream-068.zeno.fm/hdwjyvwyrietv?zs=SeajR3Q4QbCF62bQdG06VA" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>



            <div class="program-widget">
                <h2 v-if="activeProgram">{{ activeProgram.title }}
                    <span class="program-time"> {{ hm(activeProgram.startTime) }} - {{
                        hm(activeProgram.endTime) }}
                    </span>

                </h2>
                <router-link to="/program"> ● MORE PROGRAMS</router-link>
            </div>
            <div class="audio-preview">
                <canvas ref="canvas"></canvas>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'fixedbar',
    data() {
        return {
            play: true,
            file: 'ikipe yapfubye.mp3',
            context: null,
            src: null,
            analyser: null,
            dataArray: null,
            bufferLength: null,
            WIDTH: 0,
            HEIGHT: 0,
            barWidth: 0,
            x: 0,
            barHeight: 0,
            canvas: null,
            ctx: null,
            activeProgram: null,
            allprograms: [],
        }
    },
    computed: {
        todayDay() {
            let day = new Date().getDay()
            if (day == 0) { return "Sunday" }
            else if (day == 1) { return "Monday" }
            else if (day == 2) { return "Tuesday" }
            else if (day == 3) { return "Wednesday" }
            else if (day == 4) { return "Thursday" }
            else if (day == 5) { return "Friday" }
            else if (day == 6) { return "Saturday" }
        },
        dayPrograms() {
            let vm = this
            return vm.allprograms.filter(program => program.day == vm.todayDay)
        }
    },
    mounted() {
        let vm = this
        vm.$getAllPrograms();
        vm.timer = setInterval(() => {
            vm.checkActiveProgram(vm.dayPrograms);
        }, 1000);
    },
    methods: {
        hm(time) {
            return time.split(':')[0] + ":" + time.split(':')[1]
        },
        checkActiveProgram(dayPrograms) {
            let vm = this
            let now = new Date()
            let nowTime = now.getHours() + ":" + now.getMinutes()
            let activeProgram = dayPrograms.filter(program => program.startTime <= nowTime && program.endTime >= nowTime)
            if (activeProgram.length > 0) {
                vm.activeProgram = activeProgram[0];
            } else {
                vm.activeProgram = false;
            }
        },
        visualizer() {
            let vm = this
            // vm.context = new AudioContext();
            // vm.canvas = vm.$refs.canvas;
            let audioPlayer = document.getElementById('radioPlayer')
            // vm.ctx = vm.canvas.getContext('2d')
            // vm.src = vm.context.createMediaElementSource(audioPlayer)
            // vm.analyser = vm.context.createAnalyser()
            // vm.src.connect(vm.analyser)
            // vm.analyser.connect(vm.context.destination)
            // vm.analyser.fftSize = 256
            // vm.bufferLength = vm.analyser.frequencyBinCount
            // vm.dataArray = new Uint8Array(vm.bufferLength)
            // vm.canvas.width = vm.canvas.offsetWidth
            // vm.canvas.height = vm.canvas.offsetHeight
            // vm.WIDTH = vm.canvas.offsetWidth
            // vm.HEIGHT = vm.canvas.offsetHeight
            // vm.barWidth = (vm.WIDTH / vm.bufferLength) * 0.6
            // vm.x = 0
            // check player if is playing
            if (!audioPlayer.paused) {
                this.play = false;
                audioPlayer.play();
            }
            // vm.renderFrame()
        },
        renderFrame() {
            let vm = this
            requestAnimationFrame(() => { vm.renderFrame() })
            vm.x = 0
            vm.analyser.getByteFrequencyData(vm.dataArray)
            vm.ctx.fillStyle = '#e7e7e7'
            vm.ctx.fillRect(0, 0, vm.WIDTH, vm.HEIGHT)
            for (let i = 0; i < vm.bufferLength; i++) {
                vm.barHeight = vm.dataArray[i]
                let r = vm.barHeight + (2 * (i / vm.bufferLength))
                let g = 100 * (i / vm.bufferLength)
                let b = 100
                // let r = 15
                // let g = 20 * (i / vm.bufferLength)
                // let b = 42
                vm.ctx.fillStyle = `rgb(${r}, ${g}, ${b})`
                // vm.ctx.fillStyle = `#0e142aad`
                vm.ctx.fillRect(vm.x, vm.HEIGHT - vm.barHeight, vm.barWidth, vm.barHeight)
                vm.x += this.barWidth + 4
            }
        },
        playPod() {
            let audioPlayer = document.getElementById('radioPlayer');
            document.querySelectorAll('audio').forEach(aud => {
                aud.pause()
            })
            if (this.play) {
                audioPlayer.play()
                this.play = false
            } else {
                audioPlayer.pause()
                this.play = true
            }
        }
    }
}
</script>
<style lang="scss">
.fixed {
    width: 100%;
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 260px;
    background: #dedede;
    border-top: 2px solid #5c689e50;
    color: #0e142aad;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 10px;

    @media (max-width: 1070px) {
        left: 0;
    }

    @media (max-width: 570px) {
        padding: 7px 10px;
    }

    .playbutton {
        all: unset;
        width: 30px;
        height: 30px;
        cursor: pointer;
        border-radius: 100%;
        background: #0E142A;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        img {
            width: 12px;
            height: 12px;
        }
    }

    .left {
        display: flex;
        width: calc(100% - 60px);
        height: 100%;

        @media (max-width: 570px) {
            width: 100%;
        }



        .program-widget {
            display: flex;
            align-items: center;
            width: 500px;

            @media (max-width: 1070px) {
                width: 100%;
            }

            h2 {
                font-size: 0.8rem;
                margin-right: 10px;
                overflow: hidden;
                text-decoration: none;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 320px;
                text-transform: uppercase;

                .program-time {
                    color: #067a6a;
                    margin-left: 30px;
                }

                @media (max-width: 1070px) {
                    width: 100%;
                }

                @media (max-width: 570px) {
                    font-size: 0.75rem;
                }
            }

            a {
                width: calc(100% - 320px);
                font-size: 0.8rem;
                text-decoration: none;
                color: #0E142A;

                @media (max-width: 1070px) {
                    display: none;
                }

                @media (max-width: 570px) {
                    font-size: 0.75rem;
                }
            }
        }

        .audio-preview {
            position: relative;
            height: 100%;
            width: calc(100% - 400px);

            @media (max-width: 1070px) {
                display: none;
            }

            @media (max-width: 570px) {
                height: 30px;
            }

            border-bottom: 1px solid #d4d4d4;

            canvas {
                position: absolute;
                width: 100%;
                height: 100%;
                background: transparent !important;
            }
        }
    }
}
</style>
<template>
    <div class="wholeloader">
      <div>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </template>
  
  <style scoped>
  .wholeloader {
    position: fixed;
    width: calc(100vw - 260px);
    min-height: 100%;
    z-index: 99;
    background: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 1070px) {
    .wholeloader{
      width: 100%;
      top: 0;
      left: 0;
    }
  }
  
  .wholeloader div {
    position: absolute;
  }
  
  span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #e0291b;
    position: absolute;
    animation: audio-wave 1.5s infinite ease-in-out;
  
  }
  
  span:nth-child(2) {
    left: 11px;
    animation-delay: 0.2s;
  }
  
  span:nth-child(3) {
    left: 22px;
    animation-delay: 0.4s;
  }
  
  span:nth-child(4) {
    left: 33px;
    animation-delay: 0.6s;
  }
  
  span:nth-child(5) {
    left: 44px;
    animation-delay: 0.8s;
  }
  
  @media (min-width: 1px) {
    span{
      left: -22px;
    }
    span:nth-child(2) {
      left: -11px;
    }
    span:nth-child(3) {
      left: 0;
    }
    span:nth-child(4) {
      left: 11px;
    }
    span:nth-child(5) {
      left: 22px;
    }
  }

  @keyframes audio-wave {
    0% {
      height: 5px;
      transform: translateY(0px);
      background: #e0291b;
    }
    25% {
      height: 40px;
      transform: translateY(20px);
      background: #0e142a;
    }
  
    /*effect is to animate the height of each span from 5px to 30px*/
    /*translateY makes Y axis move down to give the effect that it is growing from the center*/
  
    50% {
      height: 5px;
      transform: translateY(0px);
      background: #e0291b;
    }
    100% {
      height: 5px;
      transform: translateY(0px);
      background: #e0291b;
    }
  }
  </style>  
<template>
    <div class="chat-header flex w-full place-items-center p-2 border-b-2  h-15 border-gray-200 bg-gray-300">
        <div class="profile-icon w-12 h-12 mr-1">
            <img src="@/assets/profiles/avatar.jpg" class="w-full h-full rounded-full">
        </div>
        <div class="chat-content w-10/12">
            <h3 class="text-base">Abe Jahwin</h3>
            <p class="text-sm">ajahwin@gmail.com</p>
        </div>
        <div class="chat-options w-5">

        </div>
    </div>
</template>
  
<script>
export default {
    name: 'chat-header',
    props: {},
    data: () => {
        return {

        }
    },
    methods: {

    },
}
</script>

<style scoped type="scss">
.chat-header {
    height: 70px;
}
</style>

import { CREATE_UPLOAD, DELETE_UPLOADED_FILE } from "../actions/upload";
import { API_BASE_URL, CREATE_UPLOAD_URL, DELETE_UPLOADED_FILE_URL } from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [CREATE_UPLOAD]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_UPLOAD_URL,
            method: "POST",
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + getters.get_access_token
            },
            data: payload,
        });
    },
    [DELETE_UPLOADED_FILE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_UPLOADED_FILE_URL,
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + getters.get_access_token
            },
            data: payload,
        });
    }

};
export default {
    mutations,
    actions,
    getters,
    state
};
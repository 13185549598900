<template>
    <div class="conversation-item flex w-full place-items-center p-2 border-b-2 border-gray-200">
        <div class="profile-icon w-12 h-12 mr-1">
            <img src="@/assets/profiles/avatar.jpg" class="w-full h-full rounded-full">
        </div>
        <div class="conversation-content w-9/12">
            <h3 class="text-base">Abe Jahwin</h3>
            <p class="text-sm">Hello there, This is my message</p>
        </div>
        <div class="conversation-options w-5">
            <DropDown :actions="[
                { text: 'Delete' }
            ]"></DropDown>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'conversation-item',
    props: {},
    methods: {

    },
}
</script>

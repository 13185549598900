<template>
    <div class="page-wrapper">
        <Modal v-if="isDelete" @click.self="close()">
            <div class="delete">
                <h2>Are you sure you want to Delete this Podcast?</h2>
                <div class="buttons">
                    <button class="cancel" @click="close()">Cancel</button>
                    <button class="del" @click="confirmDelete()">Delete</button>
                </div>
            </div>
        </Modal>
        <Modal v-if="viewmodal" @click.self="close()">
            <form @submit.prevent="createPodcast" v-if="modalType == 'add'">
                <div class="name-close">
                    <h1>Add Podcast</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Title</label>
                    <input type="text" v-model="podcastForm.title" class="input" required>
                    <label>Description</label>
                    <textarea v-model="podcastForm.description" required></textarea>
                    <label>Image</label>
                    <uploader type="image/*" placeholder="Select image file" @onFileUploaded="uploadedImg($event)" v-model="podcastForm.imageFileName" />
                    <label>Audio</label>
                    <uploader type="audio/*" @onFileUploaded="uploadedAud($event)" v-model="podcastForm.audioFileName" />
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Create</button>
                </div>
            </form>
            <form @submit.prevent="updatePodcast" v-else>
                <div class="name-close">
                    <h1>Add Podcast</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Title</label>
                    <input type="text" v-model="editPodcastForm.title" class="input" required>
                    <label>Description</label>
                    <textarea v-model="editPodcastForm.description" required></textarea>
                    <label>Image</label>
                    <uploader type="image/*" @onFileUploaded="uploadedImg($event)" v-model="editPodcastForm.imageFileName" />
                    <label>Audio</label>
                    <uploader type="audio/*" @onFileUploaded="uploadedAud($event)" v-model="editPodcastForm.audioFileName" />
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Update</button>
                </div>
            </form>
        </Modal>
        <div class="header">
            <button @click="viewModal('add')">Add new Podcast</button>
        </div>
        <div v-if="!allpodcasts.length" class="empty">No Podcasts available!</div>
        <div v-else class="table">
            <div class="headings">
                <h2>title</h2>
                <h2>Cover</h2>
                <h2>descrption</h2>
                <h2></h2>
            </div>
            <div class="details" v-for="podcast in allpodcasts" :key="podcast.id">
                <h3>{{ podcast.title }}</h3>
                <div class="img"><img :src="assetsUrl + podcast.imageFileName"></div>
                <h3>{{ podcast.description }}</h3>
                <div class="edit">
                    <div class="icon">
                        <img src="@/assets/svg/options.svg" @click="podcast.options = !podcast.options" alt="">
                        <div class="options" :class="{ active: podcast.options }">
                            <div @click="viewModal('edit', podcast)">
                                <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                Edit
                            </div>
                            <div @click="$delete(podcast)">
                                <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ASSETS_BASE_URL } from '@/store/variables'
export default {
    name: 'Admin-Podcast',
    data() {
        return {
            assetsUrl: ASSETS_BASE_URL,
            isLoading: false,
            isDelete: false,
            viewmodal: false,
            editPodcastForm: null,
            modalType: null,
            podcastForm: {
                title: '',
                description: '',
                imageFileName: '', //1681347365099.jpeg
                audioFileName: '', //1681347365099.mp3
            },
            deleteId: '',
            allpodcasts: [],
        }
    },
    methods: {
        viewModal(type, podcast) {
            let vm = this
            vm.modalType = type
            type == "edit" ? ((podcast.options = false), (vm.editPodcastForm = { ...podcast })) : false
            vm.viewmodal = true
        },
        close() {
            let vm = this
            vm.isDelete = false
            vm.deleteId = ''

            vm.viewmodal = false
            vm.modalType = null
            vm.editPodcastForm = null

            vm.podcastForm.title = ''
            vm.podcastForm.description = ''
            vm.podcastForm.imageFileName = ''
            vm.podcastForm.audioFileName = ''
        },
        uploadedAud(load) {
            this.podcastForm.audioFileName = load.fileName
        },
        uploadedImg(load) {
            this.podcastForm.imageFileName = load.fileName
        },
        createPodcast() {
            let vm = this;
            vm.$store
                .dispatch('CREATE_PODCAST', vm.podcastForm)
                .then((response) => {
                    vm.isLoading = false
                    vm.$notify({
                        title: 'Important Message',
                        text: 'Added Successfully'
                    })
                    vm.close();
                    vm.getAllPodcasts();
                })
                .catch(err => {
                    if (Array.isArray(err.response.data.message)) {
                        err.response.data.message.forEach(alert => {
                            vm.$notify({
                                type: 'error',
                                title: 'Important Message',
                                text: alert
                            })
                        })
                    } else {
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: err.response.data.message
                        })
                    }
                });
        },
        updatePodcast() {
            let vm = this;
            vm.$store
                .dispatch('UPDATE_PODCAST', vm.editPodcastForm)
                .then((response) => {
                    vm.isLoading = false
                    vm.$notify({
                        title: 'Important Message',
                        text: 'Updated Successfully'
                    })
                    vm.close();
                    vm.getAllPodcasts();
                })
                .catch(err => {
                    if (Array.isArray(err.response.data.message)) {
                        err.response.data.message.forEach(alert => {
                            vm.$notify({
                                type: 'error',
                                title: 'Important Message',
                                text: alert
                            })
                        })
                    } else {
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: err.response.data.message
                        })
                    }
                });
        },
        confirmDelete() {
            let vm = this;
            vm.$store
                .dispatch("DELETE_PODCAST", vm.deleteId)
                .then((response) => {
                    vm.close()
                    vm.getAllPodcasts()
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: response.data.message
                    })
                })
        },
        getAllPodcasts() {
            let vm = this;
            vm.isLoading = true
            vm.$store
                .dispatch("GET_ALL_PODCASTS")
                .then((response) => {
                    vm.isLoading = false
                    vm.allpodcasts = response.data.data
                    vm.allpodcasts.forEach(podcast => {
                        podcast.options = false
                    })
                })
                .catch(response => {
                    vm.isLoading = false;
                });
        }
    },
    mounted() {
        let vm = this
        vm.getAllPodcasts()
    }
}
</script>
<style scoped lang="scss">
.page-wrapper {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin-left: 260px;

    @media (max-width: 1070px) {
        margin: 0px;
    }

    .empty {
        text-align: center;
        font-size: 0.85rem;
        color: #666666;
    }

    .header {
        padding: 10px 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        button {
            outline: none;
            border: 1px solid #0e142a60;
            border-radius: 7px;
            position: relative;
            overflow: hidden;
            border: none;
            font-size: 0.85rem;
            padding: 8px 25px;
            background: #0E142A;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .table {
        width: 100%;
        padding: 10px 30px;

        .headings,
        .details {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        .headings {
            padding: 7px 4px;

            h2 {
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.85rem;
            }
        }

        .details {
            margin: 10px 0;
            padding: 7px 4px;
            border-radius: 5px;

            // &:nth-child(even) {
            //     background: #0e142a7e;

            //     h3 {
            //         color: #fff;
            //     }
            // }

            h3 {
                padding: 0 0 0 5px;
                font-size: 0.85rem;
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .img{
                width: 300px;
                height: 150px;
                position: relative;
                overflow: hidden;
                border-radius: 7px;
                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
            .edit{
                .icon{
                    margin: auto;
                    position: relative;
                    width: 20px;
                    height: 20px;
                    img{
                        width: 100%;
                        height: 100%;
                        top: 50%;
                        left: 50%; 
                        position: absolute;
                        transform: translate(-50%,-50%);
                        cursor: pointer;
                    }
                    .options{
                        display: none;
                        column-gap: 30px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0.8rem;
                        right: 100%;
                        z-index: 2;
                        padding: 5px 14px;
                        &.active{
                            display: flex;
                        }
                        div{
                            cursor: pointer;
                            display: flex;
                            column-gap: 7px;
                            border-radius: 5px;
                            padding: 3px 14px;
                            background: #ccc;
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
  <div class="content">
    <loader v-if="isLoading" />
    <div class="allnews">
      <h2 class="header">News</h2>
      <div class="categories">
        <categories @onnavigate="navigate($event,id)" />
      </div>
      <div class="news" v-if="allnews.length">
        <router-link :to="{ path: '/news/' + article.id }" v-for="article in allnews" :key="article.id">
          <div class="article-img">
            <img :src="assetsUrl + article.imageFileName" alt="">
          </div>
          <div class="description">
            <h2 class="heading">{{ article.title }}</h2>
            <p class="time">{{ datetime(article.createdAt) }}</p>
            <p class="newsarticle" v-html="article.content"></p>
          </div>
        </router-link>
      </div>
      <div v-else class="empty">Not Available!</div>
    </div>
  </div>
</template>
  
<script>
import { API_BASE_URL, CREATE_UPLOAD_URL, ASSETS_BASE_URL } from '@/store/variables'
export default {
  name: 'News',
  data() {
    return {
      assetsUrl: ASSETS_BASE_URL,
      isLoading: false,
      viewsearch: false,
      searched: '',
      allnews: [],
    }
  },
  methods: {
    urlHyphenate(title) {
      title = title.trim()
      title = title.replace(/\s+/g, '-').toLowerCase()
      title = title.replace(/[^\w\-]+/g, '').toLowerCase()
      return title
    },
    searchthis(searched) {
      this.viewsearch = true
      this.searched = searched
    },
    datetime(time) {
      return time.split('T')[1].split(':')[0] + ":" + time.split('T')[1].split(':')[1] + ", " + time.split('T')[0]
    },
    navigate(id){
      let vm = this 
      if(id == 0){
        vm.$getAllNews()
      }else{
        vm.isLoading = true
        vm.$store
        .dispatch("GET_SINGLE_CATEGORY", id)
        .then(resp =>{
            vm.isLoading = false
            vm.allnews = resp.data.data
        })
        .catch(err => console.log(err))
      }
    }
  },
  mounted() {
    let vm = this
    vm.$getAllNews()
    vm.$store.state.openSidebar = false
  }
}
</script>
<style scoped lang="scss">
.content {
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  padding: 0 0 50px 0;
  @media (max-width: 991px) {
    padding: 0px 0px 50px 0px;
  }

  &::-webkit-scrollbar {
    width: 10px;

    @media (max-width: 480px) {
      width: 5px;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 20px;
    background: #0E142A;
  }

  .search-enter-active,
  .search-leave-active {
    transition: .5s;
  }

  .search-enter-from,
  .search-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }

  .empty {
      width: 100%;
      color: #646464;
      text-align: center;
  }
  .allnews {
    padding: 10px 40px;

    @media (max-width: 450px) {
      padding: 10px 20px;
    }
    .header {
      padding: 10px 0px;
      border-bottom: 3px solid #E0291B;
      h2 {
          font-size: 1rem;
          font-weight: 600;
      }
    }

    .news {
      display: grid;
      column-gap: 20px;
      row-gap: 40px;
      margin: 30px 0;
      grid-template-columns: 1fr 1fr 1fr; 
      @media (max-width: 840px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 550px) {
        grid-template-columns: 1fr;
      }

      a {
        text-decoration: none;
        color: #0E142A;

        .article-img {
          width: 100%;
          height: 200px;
          position: relative;
          overflow: hidden;
          border-radius: 6px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .description {
          padding: 10px 0px;
          width: 100%;

          .heading {
            overflow: hidden;
            font-size: 0.95rem;
            box-orient: vertical;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            @media (max-width: 840px) {
              line-clamp: 2;
              -webkit-line-clamp: 2;
            }
          }

          .time {
            font-size: 0.8rem;
            color: #646464;
            margin: 5px 0;
          }

          .newsarticle {
            font-size: 0.8rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;

            @media (max-width: 840px) {
              line-clamp: 3;
              -webkit-line-clamp: 3;
            }

            @media (max-width: 768px) {
              max-height: 65px;
            }
          }
        }
      }
    }
  }
}
</style>
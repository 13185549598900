<template>
    <div class="modal">
        <slot></slot>
    </div>
</template>
<script>
    export default{
        name: 'Modal',
        data(){
            return{
    
            }
        }
    }
</script>
<style lang="scss">
.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000063;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e6e6e6; 
    form{
        padding: 30px 40px;
        border-radius: 7px;
        width: 500px;
        background: #e6e6e6; 
        .name-close{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1{
                font-size: 0.9rem;
                color: #0E142A;
            }
            .icon{
                width: 25px;
                height: 25px;
                position: relative;
                margin-right: 20px;
                cursor: pointer;
                img{
                    width: 70%;
                    height: 70%;
                    top: 50%;
                    left: 50%; 
                    position: absolute;
                    transform: translate(-50%,-50%);
                }
            }
        }
        .inputs{
            margin: 15px 0;
            label{
                color: #0E142A;
                display: block;
                font-size: 0.85rem;
            }
            .input,textarea,select{
                color: #0E142A;
                font-size: 0.8rem;
                background: transparent;
                padding: 4px 10px;
                margin: 5px 0;
                border-radius: 3px;
                width: 100%;
                outline: none;
                resize: none;
            }
            input{
                font-size: 0.8rem;
                background: transparent;
                border: none;
                border: 1px solid #0e142a60;
            }
            .flex{
                display: flex;
                justify-content: space-between;
                column-gap: 5px;
            }
        }
        .buttons{
            display: flex;
            justify-content: space-around;
            button,.upload{
                font-size: 0.8rem;
                padding: 3px 25px;
                background: none;
                border: 1px solid transparent;
                outline: none;
                border-radius: 4px;
                margin-right: 30px;
                cursor: pointer;
                &.cancel{
                    color: #0E142A;
                    border: 1px solid #0e142a50;
                }
                &.save{
                    color: #fff;
                    background: #0E142A;
                }
            }
            .upload{
                color: #fff;
                background: #0E142A;
            }
        }
    }
    .delete{
            width: 400px;
            border-radius: 7px;
            height: fit-content;
            padding: 20px;
            background: #e6e6e6; 
            text-align: center;
            h2{
                color: #0E142A;
                font-size: 0.85rem;
                font-weight: 500;
            }
            .buttons{
                display: flex;
                justify-content: space-around;
                margin-top: 20px;
                button{
                    padding: 3px 25px;
                    font-size: 0.8rem;
                    background: none;
                    border: 1px solid transparent;
                    outline: none;
                    border-radius: 4px;
                    cursor: pointer;
                    &.cancel{
                        color: #0E142A;
                        border: 1px solid #0e142a50;
                    }
                    &.del{
                        color: #fff;
                        background: #0E142A;
                    }
                }
            }
        }
}
</style>
<template>
    <div class="contain" :class="{ oncontain: openside }" @click.self="closeside">
        <div class="sidebar" :class="{ onside: openside }">
            <router-link to="/" class="logo">
                <img src="@/assets/logo.svg" alt="">
            </router-link>
            <div class="main side-section">
                <router-link to="/admin">
                    <div class="icon">
                        <img src="@/assets/svg/live.svg" alt="">
                    </div>
                    Programs
                </router-link>
                <router-link to="/admin/podcast">
                    <div class="icon">
                        <img src="@/assets/svg/podcast.svg" alt="">
                    </div>
                    Podcasts
                </router-link>
                <router-link to="/admin/news"
                    :class="{ 'router-link-exact-active': activelink, 'router-link-active': activelink }">
                    <div class="icon">
                        <img src="@/assets/svg/news.svg" alt="">
                    </div>
                    News
                </router-link>
                <router-link to="/admin/support"
                    :class="{ 'router-link-exact-active': activelink, 'router-link-active': activelink }">
                    <div class="icon">
                        <img src="@/assets/svg/chat.svg" alt="">
                    </div>
                    Support Chat
                </router-link>
                <router-link to="/admin/users"
                    :class="{ 'router-link-exact-active': activelink, 'router-link-active': activelink }">
                    <div class="icon">
                        <img src="@/assets/svg/users.svg" alt="">
                    </div>
                    Users
                </router-link>
                <router-link to="/admin/site"
                    :class="{ 'router-link-exact-active': activelink, 'router-link-active': activelink }">
                    <div class="icon">
                        <img src="@/assets/svg/site.svg" alt="">
                    </div>
                    Site
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sidebar',
    data() {
        return {
            activelink: false
        }
    },
    computed: {
        openside() {
            return this.$store.state.openSidebar;
        },
    },
    methods: {
        closeside() {
            this.$store.state.openSidebar = false
        },
    },
    mounted() {
        this.closeside()
    }
}    
</script>
<style lang="scss" scoped>
.contain {
    width: 22%;
    height: 100%;

    @media (max-width: 1070px) {
        background: #00000063;
        width: 100%;
        height: 100%;
        z-index: 999999;
        position: fixed;
        left: 0;
        opacity: 0;
        transition: .6s;
        pointer-events: none;

        &.oncontain {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    background: #e7e7e7;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    row-gap: 10px;
    border-right: 1px solid #bababa50;
    overflow-y: auto;

    @media (max-width: 1070px) {
        transition-delay: 1s;
        transition: .6s;
        width: 250px;
        opacity: 0;
        transform: translateX(-50px);

        &.onside {
            transform: translateX(0px);
            opacity: 1;
            pointer-events: all;
        }
    }

    .logo {
        flex-shrink: 0;
        margin-left: 30px;
        margin-bottom: 20px;
        width: fit-content;

        img {
            width: 100px;
        }
    }

    .side-section {
        // border: 1px solid salmon;
        padding: 0 30px;
        width: 100%;
        height: fit-content;

        a {
            // border: 1px solid salmon;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 35px auto;
            font-size: 0.8rem;
            text-decoration: none;
            color: #0E142A;
            transition: .6s;
            text-transform: uppercase;

            &.router-link-exact-active {
                color: #E0291B;

                .icon {
                    background: #E0291B;
                }
            }

            &:hover {
                color: #E0291B;

                .icon {
                    background: #E0291B;
                }
            }

            .icon {
                transition: .6s;
                margin: 0 20px 0 0;
                position: relative;
                width: 25px;
                height: 25px;
                flex-shrink: 0;
                cursor: pointer;
                border-radius: 100%;
                background: #0E142A;

                img {
                    width: 50%;
                    height: 50%;
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}
</style>
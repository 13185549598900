<template>
    <div class="admin-wrapper">
        <sidebar />
        <navbar />
        <router-view />
    </div>
</template>
<script>
import sidebar from './components/Admin/sidebar.vue';
import navbar from './components/Admin/navbar.vue';
export default {
    name: 'AdminWrapper',
    components: { sidebar, navbar },
    data() {
        return {}
    },
    methods: {
        logged() {
            let loggeduser = JSON.parse(localStorage.getItem("loggeduser"))
            if (loggeduser) {
                this.$store.dispatch("setAccessToken", loggeduser.accessToken);
            } else {
                localStorage.removeItem("loggeduser");
                vm.$store.dispatch("removeAccessToken");
                this.$router.push('/admin/login')
            }
        }
    },
    beforeMount() {
        this.logged()
    },
    mounted() {

    }
}
</script>
<style lang="scss"></style>

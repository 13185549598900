<template>
    <van-popover v-model:show="showPopover" :actions="actions" @select="onSelect">
        <template #reference>
            <button>
                <img src="@/assets/svg/options.svg" class="w-6" />
            </button>
        </template>
    </van-popover>
</template>
<script>
export default {
    name: 'dropdown',
    props: {
        actions: {
            type: Array,
            default: []
        }
    },
    data: () => {
        return {
            showPopover: false,
        }
    },
    methods: {
        onSelect(action) {
            let vm = this;
            vm.$emit('onSelect', action);
        }
    },
}
</script>
<style lang="scss"></style>
import { GET_ALL_SITE_CONTENTS,CREATE_SITE_CONTENT,UPDATE_SITE_CONTENT,DELETE_SITE_CONTENT } from "../actions/site";
import { API_BASE_URL, GET_ALL_SITE_CONTENTS_URL,CREATE_SITE_CONTENT_URL,UPDATE_SITE_CONTENT_URL,
    DELETE_SITE_CONTENT_URL} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [GET_ALL_SITE_CONTENTS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_SITE_CONTENTS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [CREATE_SITE_CONTENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_SITE_CONTENT_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [UPDATE_SITE_CONTENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + UPDATE_SITE_CONTENT_URL + payload.id,
            method: "PUT",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [DELETE_SITE_CONTENT]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_SITE_CONTENT_URL + payload,
            method: "DELETE",
            // data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
};
export default {
    mutations,
    actions,
    getters,
    state
};
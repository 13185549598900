<template>
    <div class="navbar">
        <div class="menuLogo">
            <div class="menu" @click="activateSide">
                <div class="icon">
                    <img src="@/assets/svg/menu.svg">
                </div>
            </div>
        </div>
        <router-link to="/" class="reallogo">
            <img src="@/assets/logo.svg" alt="">
        </router-link>
        <div class="profile" @click="isLogout = !isLogout">
            <img :src="require('@/assets/profiles/avatar.jpg')" alt="">
        </div>
        <div v-if="isLogout" @click="logout()" class="logout">
            <div class="icon">
                <img src="@/assets/svg/logout.svg" alt="">
            </div>
            Log out !
        </div>
    </div>
</template>
<script>
export default {
    name: 'navbar',
    data() {
        return {
            isLogout: false,
        }
    },
    methods: {
        activateSide(){
            this.$store.state.openSidebar = true
        },
        logout(){
            let vm = this;
            localStorage.removeItem('loggeduser');
            vm.$router.push({path: '/admin/login'});
            window.location.reload()
        },
    }
}
</script>
<style scoped lang="scss">
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    height: 70px;
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    z-index: 99;
    background: #F8F8F8;
    border-bottom: 1px solid #bababa50;
    @media (min-width: 1070px){
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
    }
    @media (max-width: 1070px) {
        left: 0;
    }
    @media (max-width: 450px) {
        padding: 0px 25px;
    }
    .menuLogo{
        display: none;
        align-items: center;
        @media (max-width: 1070px) {
            display: flex;
        }
        .menu{
            .icon{
                margin: auto;
                position: relative;
                width: 25px;
                height: 25px;
                cursor: pointer;
                img{
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    left: 50%; 
                    position: absolute;
                    transform: translate(-50%,-50%);
                }
            }
        }
    }
    .reallogo {
        display: none;
        @media (max-width: 1070px) {
            display: flex;
            flex-shrink: 0;
            img {
                width: 80px;
            }
        }
    }
    .profile{
        position: relative;
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 100%;
        cursor: pointer;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    .logout{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 100px;
        background: #ccc;
        padding: 5px 10px;
        z-index: 9999999;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;
        font-size: 0.8rem;
        .icon {
            transition: .6s;
            margin: 0 20px 0 0;
            position: relative;
            width: 25px;
            height: 25px;
            flex-shrink: 0;
            cursor: pointer;
            border-radius: 100%;
            img {
                width: 80%;
                height: 80%;
                top: 50%;
                left: 50%;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
    }
}
</style>
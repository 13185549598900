import { SEND_MESSAGE } from "../actions/contact";
import { API_BASE_URL, SEND_MESSAGE_URL} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [SEND_MESSAGE]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEND_MESSAGE_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
};
export default {
    mutations,
    actions,
    getters,
    state
};
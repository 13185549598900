import { GET_ALL_PROGRAMS,CREATE_PROGRAM,UPDATE_PROGRAM,DELETE_PROGRAM } from "../actions/programs";
import { API_BASE_URL, GET_ALL_PROGRAMS_URL,CREATE_PROGRAM_URL,UPDATE_PROGRAM_URL,DELETE_PROGRAM_URL} from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [GET_ALL_PROGRAMS]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + GET_ALL_PROGRAMS_URL,
            method: "GET",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [CREATE_PROGRAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + CREATE_PROGRAM_URL,
            method: "POST",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [UPDATE_PROGRAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + UPDATE_PROGRAM_URL + payload.id,
            method: "PUT",
            data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
    [DELETE_PROGRAM]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + DELETE_PROGRAM_URL + payload,
            method: "DELETE",
            // data: payload,
            headers: { 'Authorization': 'Bearer ' + getters.get_access_token }
        });
    },
};
export default {
    mutations,
    actions,
    getters,
    state
};
<template>
    <div class="site-wrapper">
        <sidebar @onOpenSupportChart="onOpenSupportChart()" />
        <navbar />
        <div class="page-wrapper">
            <router-view />
        </div>
        <fixedbar />
        <SupportChat @close="show_chat = false" :class="{ show: show_chat }" />
    </div>
</template>
<script>
export default {
    name: 'SiteWrapper',
    components: {

    },
    data() {
        return {
            viewsearch: false,
            show_chat: false,
        }
    },
    methods: {
        onOpenSupportChart() {
            this.show_chat = true
        },
        urlHyphenate(title) {
            let url = title.trim()
            return url.replace(/\s+/g, '-').toLowerCase()
        },
    },
    mounted() {
        let vm = this
        vm.$store.state.openSidebar = false
        window.scroll(0, 0);
    }
}
</script>
<style lang="scss">
.site-wrapper {
    .page-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 40px;
        z-index: 99;
        margin-left: 260px;
        display: flex;

        @media (max-width: 1070px) {
            top: 70px;
            margin: 0px;
        }
    }
}
</style>

<template>
  <div class="support-chat-wrapper">
    <div class="support-chat-widget">
      <div class="support-chat-header">
        <div class="left-panel">
          <button @click="closeChat()" v-if="view_type == 'starting'" class="btn btn-close">
            &times;
          </button>
          <button @click="backToConversation()" v-if="view_type == 'chatting'" class="btn btn-close">
            <img width="30" src="@/assets/images/icons/arrow-left.svg" />
          </button>
        </div>
        <div class="right-panel">
          <h3>Support chat</h3>
          <p v-if="
            view_type == 'chatting' &&
            selected_conversation &&
            selected_conversation.status == 'OPENED'
          ">
            Tell us what we can help you
          </p>
          <p v-if="isLoggedIn && view_type == 'starting'">
            List of conversation
          </p>
          <p v-if="!isLoggedIn && view_type == 'starting'">
            We need to know you
          </p>
          <p v-if="
            selected_conversation && selected_conversation.status == 'CLOSED'
          ">
            Closed
          </p>
        </div>
      </div>
      <!-- List of conversations -->
      <div v-if="isLoggedIn && view_type == 'starting'" class="support-chat-body fit-bottom">
        <vue-scroll>
          <div class="chat-conversation">
            <div class="chat-conversation-addnew-widget">
              <CButton :loading="starting_conversation_loading" @click="startConversation()"
                class="chat-conversation-addnew-btn">
                + Start New Conversation
              </CButton>
            </div>
            <div class="chat-conversation-item" v-for="(item, index) in conversations" :key="index"
              @click="selectConversation(item)">
              <div class="chat-conversation-profile">
                <img src="@/assets/images/customer-service.png" />
                <div v-if="item.status == 'CLOSED'" class="chat-conversation-status opened closed">
                  Closed
                </div>
                <div v-if="item.status == 'OPENED'" class="chat-conversation-status opened opened">
                  Opened
                </div>
              </div>
              <div class="chat-conversation-content">
                <div class="chat-profile-name">Support team</div>
                <div class="chat-last-message" v-if="item.last_message">
                  <span v-if="item.last_message.message_type == 'text'">{{
                    item.last_message.message_content
                  }}</span>
                  <span v-if="item.last_message.message_type == 'image'">
                    <img src="@/assets/images/icons/image.svg" width="12" />
                    Image
                  </span>
                </div>
              </div>
              <div class="chat-status">
                <div v-if="item.last_message" class="message-time">
                  {{ moment(item.last_message.created_at).format("hh:mm A") }}
                </div>
                <div class="tag-success" v-if="item.count_unread > 0">
                  {{ item.count_unread }}
                </div>
              </div>
            </div>
          </div>
        </vue-scroll>
      </div>
      <!-- Start conversation as guest -->
      <div v-if="!isLoggedIn && view_type == 'starting'" class="support-chat-body fit-bottom">
        <div class="chat-conversation-guest">
          <div class="input-group">
            <label>Enter your names</label>
            <div class="input-widget">
              <input v-model="conversation_form.names" type="text" class="form-control" name="names"
                placeholder="Names" />
            </div>
          </div>
          <div class="input-group">
            <label>Enter your email or phone</label>
            <div class="input-widget">
              <input v-model="conversation_form.contact" type="text" class="form-control" name="contact"
                placeholder="Email or Phone" />
            </div>
          </div>

          <div class="chat-conversation-addnew-widget">
            <button :loading="starting_conversation_loading" @click="startConversation()"
              class="btn-chat-conversation-addnew">
              Start New Conversation
            </button>
          </div>
        </div>
      </div>
      <!-- Messaging view -->
      <div v-if="view_type == 'chatting'" class="support-chat-body" :class="{
        'fit-bottom':
          selected_conversation && selected_conversation.status == 'CLOSED',
      }">
        <vue-scroll ref="vs">
          <div class="support-chat-items">
            <template v-for="(item, index) in conversation_messages">
              <!-- Received message -->
              <div v-if="item.message_owner == 'STAFF'" :key="index" class="chat-receiver-item">
                <div class="chat-item-wrapper">
                  <div class="chat-receiver-avatar">
                    <img src="@/assets/images/customer-service.png" />
                  </div>
                  <div class="chat-receiver-message">
                    <div v-if="item.message_filename" class="image">
                      <img :src="
                        $store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.message_filename
                      " />
                    </div>
                    <p>{{ item.message_content }}</p>
                    <span class="chat-time">
                      {{
                        moment(item.created_at).format("DD MMM YYYY, HH:mm")
                      }}</span>
                  </div>
                </div>
              </div>
              <!-- Sender message -->
              <div v-else class="chat-sender-item">
                <div class="chat-item-wrapper">
                  <div class="chat-sender-message">
                    <div v-if="item.message_filename" class="image">
                      <img :src="
                        $store.state.ASSETS_BASE_URL +
                        '/assets/uploaded/' +
                        item.message_filename
                      " />
                    </div>
                    <p>{{ item.message_content }}</p>
                    <span class="chat-time">
                      {{ moment(item.created_at).format("DD MMM YYYY, HH:mm") }}
                    </span>
                  </div>
                  <div class="chat-sender-avatar">
                    <img v-if="
                      selected_conversation &&
                      selected_conversation.user &&
                      selected_conversation.user.profile_image
                    " :src="
  $store.state.ASSETS_BASE_URL +
  '/assets/uploaded/' +
  selected_conversation.user.profile_image
" />
                    <img v-else src="@/assets/images/icons/empty_profile.svg" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </vue-scroll>
      </div>
      <div v-if="
        view_type == 'chatting'
      " class="support-chat-footer">
        <div v-if="isStaffTypping" class="typping-widget">
          <div class="text">Typing ...</div>
        </div>
        <div class="middle-panel">
          <input v-model="message_form.message_content" type="text" placeholder="Type your message here"
            @keypress.enter="sendMessage()" @keyup="userIsTypping" />
        </div>
        <div class="right-panel">
          <CButton :loading="loading" @click="sendMessage()" class="btn btn-send">
            <img src="@/assets/images/icons/chat-send.svg" alt="send" />
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "support-chat",
  props: {},
  components: {
  },
  sockets: {
    socketServer: {
      connect: function () { },
      supportChatMessageReceived: function (data) {
        let vm = this;
        if (vm.view_type == "chatting") {
          vm.conversation_messages.push(data);
          vm.isStaffTypping = false;
          vm.getConversations();
          // Play sound
          const audio = new Audio(vm.new_message_tone);
          audio.play();
          vm.scollBottom();
        }
      },
      supportChatRefleshConversation: function (data) {
        let vm = this;
        if (vm.view_type == "starting") {
          // Play sound
          const audio = new Audio(vm.new_conversation_tone);
          audio.play();
          vm.getConversations();
        }
      },
      supportChatTypping: function (data) {
        let vm = this;
        if (vm.view_type == "chatting") {
          if (
            vm.selected_conversation &&
            vm.selected_conversation.id == data.conversation_id
          ) {
            vm.isStaffTypping = true;
            vm.scollBottom();
            clearTimeout(vm.typpingTimer);
            vm.typpingTimer = null;
            vm.typpingTimer = setTimeout(() => {
              vm.isStaffTypping = false;
            }, 1000);
          }
        }
      },
    },
  },
  data() {
    return {
      isScollInitiated: false,
      typpingTimer: null,
      isStaffTypping: false,
      new_conversation_tone: require("@/assets/tones/new-conversation-tone.mp3"),
      new_message_tone: require("@/assets/tones/new-message-tone.mp3"),
      view_type: "starting",
      conversation_form: {
        names: "",
        contact: "",
        room: "",
      },
      message_form: {
        isNewMessage: false,
        conversation_id: null,
        message_type: "text",
        message_content: "",
        message_file_name: null,
      },
      selected_file: null,
      conversations: [],
      selected_conversation: null,
      conversation_messages: [],
      starting_conversation_loading: false,
      loading: false,
      loading_conversation: false,
      connection_timer: null,
    };
  },
  watch: {},
  computed: {

  },
  mounted() {
    let vm = this;
  },
  beforeDestroy() {

  },
  methods: {
    startConversation() {
      let vm = this;
      if (vm.conversation_form.names == "") {
        vm.$notify({
          type: 'error',
          title: 'Important Message',
          text: "Please enter your name"
        });
        return;
      }
      if (vm.conversation_form.contact == "") {
        vm.$notify({
          type: 'error',
          title: 'Important Message',
          text: "Please enter your contact"
        });
        return;
      }
      vm.view_type = "chatting";

    },
    createNewConversation() {
      let vm = this;
      vm.starting_conversation_loading = true;
      vm.$store
        .dispatch("SUPPORT_CHAT_CREATE_CONVERSATION")
        .then((response) => {
          vm.starting_conversation_loading = false;

        })
        .catch(response => {
          vm.starting_conversation_loading = false;
        });
    },
    backToConversation() {
      let vm = this;
      vm.view_type = "starting";
    },
    closeChat() {
      let vm = this;
      vm.$emit("close");
    },
    scollBottom() {
      let vm = this;
      setTimeout(() => {
        try {
          vm.$refs["vs"].scrollTo(
            {
              y: "100%",
            },
            !vm.isScollInitiated ? 0 : 400,
            "easeInQuad"
          );
        } catch (error) { }
        vm.isScollInitiated = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.support-chat-wrapper {
  position: fixed;
  width: 0px;
  height: 0px;
  right: 0px;
  z-index: 9999999;
  display: flex;
  justify-content: flex-end;
  transition: all 0.1s ease-in-out;
  background-color: transparent;

  @media screen and (max-width: 600px) {
    z-index: 99999;
  }

  .support-chat-widget {
    width: 400px;
    height: 100%;
    background-color: #171717;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #343434;
    position: relative;
    right: -400px;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    .support-chat-header {
      background-color: #111111;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;

      .left-panel {
        width: 60px;
        height: 100%;
        border-right: 1px solid #343434;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-close {
          color: #fff;
          background-color: transparent;
          border: none;
          font-size: 35px;

          img {
            margin-top: -4px;
          }
        }
      }

      .right-panel {
        width: calc(100% - 60px);
        padding-left: 15px;

        h3 {
          margin-bottom: 0px;
          font-size: 18px;
          color: rgb(231, 231, 231);
        }

        p {
          margin-bottom: 0px;
          color: rgb(173, 173, 173);
          font-size: 13px;
        }
      }
    }

    .support-chat-body {
      height: calc(100% - 140px);
      overflow-y: auto;

      &.fit-bottom {
        height: calc(100% - 70px);
      }

      .chat-conversation-guest {
        padding: 60px 30px;

        .input-group {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;

          label {
            font-size: 15px;
            color: #cfcfcf;
            margin-bottom: 8px;
          }

          .input-widget {
            .form-control {
              background-color: #343434;
              border: none;
              color: #fff;
              padding: 10px 15px;
              border-radius: 5px;
              font-size: 14px;
              width: 100%;
            }
          }
        }

        .chat-conversation-addnew-widget {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 10px;
          width: 100%;

          .btn-chat-conversation-addnew {
            height: 45px;
            border: none;
            background: #9fc953;
            border-radius: 5px;
            color: #fff;
            padding: 0px 30px;
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }

      .chat-conversation {
        .chat-conversation-addnew-widget {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 10px;
          width: 100%;
          border-bottom: 1px solid #343434;

          .chat-conversation-addnew-btn {
            height: 45px;
            border: none;
            background: #9fc953;
            border-radius: 5px;
            color: #fff;
            padding: 0px 30px;
            font-size: 14px;
            text-transform: uppercase;
          }
        }

        .chat-conversation-item {
          display: flex;
          align-items: center;
          padding: 10px 10px;
          width: 100%;
          border-bottom: 1px solid #343434;
          cursor: pointer;

          &:hover {
            background-color: #343434;
          }

          .chat-conversation-profile {
            margin-right: 15px;
            position: relative;
            display: block;

            img {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              object-fit: contain;
              background-color: #f3f3f3;
              padding: 7px;
            }

            .chat-conversation-status {
              position: absolute;
              bottom: 1px;
              right: 6px;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 11px;
              color: #fff;
              padding: 0px 7px;

              &.opened {
                background-color: #04a17e;
              }

              &.closed {
                background-color: #ab0404;
              }
            }
          }

          .chat-conversation-content {
            display: flex;
            flex-direction: column;
            width: calc(100% - 145px);

            .chat-profile-name {
              font-size: 17px;
              font-weight: 500;
              color: rgb(255, 255, 255);
            }

            .chat-last-message {
              font-size: 12px;
              color: #bbbbbb;
              overflow: hidden;
              text-decoration: none;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          .chat-status {
            width: 85px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 8px;

            .message-time {
              font-size: 12px;
              color: #d7d7d7;
            }

            .tag-success {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #00b300;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 12px;
            }
          }
        }
      }

      .support-chat-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 100px;

        .chat-receiver-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 18px;

          .chat-item-wrapper {
            max-width: 80%;
            width: max-content;
            display: flex;
            position: relative;

            .chat-receiver-avatar {
              img {
                width: 60px;
                height: 60px;
                -o-object-fit: contain;
                object-fit: contain;
                position: relative;
                top: 0px;
                left: 18px;
                border: #06383b solid 6px;
                z-index: 99;
                background: #d5d5d5;
                border-radius: 50%;
                padding: 6px;
              }
            }

            .chat-receiver-message {
              width: calc(100% - 50px);
              height: max-content;
              background-color: #015a5f;
              padding: 8px 0px;
              padding-left: 30px;
              padding-right: 10px;
              border-radius: 5px;
              position: relative;
              min-width: 140px;
              margin-bottom: 23px;

              .image {
                width: 100%;

                img {
                  width: 200px;
                  height: 150px;
                  object-fit: contain;
                  border-radius: 5px;
                  margin-bottom: 10px;
                  background-color: #000;
                }
              }

              p {
                margin: 0px;
                color: rgb(231, 231, 231);
                font-size: 13px;
              }

              span.chat-time {
                color: rgb(173, 173, 173);
                font-size: 11px;
                position: absolute;
                bottom: -20px;
              }
            }
          }
        }

        .chat-sender-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-bottom: 18px;

          .chat-item-wrapper {
            max-width: 80%;
            width: max-content;
            display: flex;
            position: relative;

            .chat-sender-message {
              width: calc(100% - 50px);
              background-color: #e6e6e6;
              padding: 8px 0px;
              padding-left: 10px;
              padding-right: 30px;
              border-radius: 5px;
              position: relative;
              height: max-content;
              text-align: left;
              min-width: 140px;
              display: flex;
              flex-direction: column;
              margin-bottom: 23px;

              .image {
                width: 100%;

                img {
                  width: 200px;
                  height: 150px;
                  object-fit: contain;
                  border-radius: 5px;
                  margin-bottom: 10px;
                  background-color: #000;
                }
              }

              p {
                margin: 0px;
                color: rgb(46, 46, 46);
                font-size: 13px;
              }

              span.chat-time {
                color: rgb(173, 173, 173);
                font-size: 11px;
                position: absolute;
                bottom: -22px;
                right: 29px;
              }
            }

            .chat-sender-avatar {
              img {
                width: 60px;
                height: 60px;
                border-radius: 50px;
                object-fit: cover;
                position: relative;
                top: -4px;
                left: -13px;
                border: #06383b solid 6px;
                border-radius: 50px;
                z-index: 99;
                background: #06383b;
              }
            }
          }
        }
      }
    }

    .support-chat-footer {
      background-color: #171717;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px;
      padding-left: 3px;
      border-top: 1px solid #272727;
      position: relative;

      .middle-panel {
        width: 100%;
        display: flex;

        input {
          width: 100%;
          background-color: transparent;
          border: none;
          color: rgb(232, 232, 232);
          padding-left: 10px;
        }
      }

      .right-panel {
        width: 60px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn-send {
          background-color: transparent;
          border: none;
          width: min-content;
          cursor: pointer;

          img {
            width: 30px;
          }
        }
      }
    }
  }

  &.show {
    display: flex;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-in-out;
    background-color: rgba(0, 0, 0, 0.8);

    .support-chat-widget {
      position: relative;
      right: 0px !important;
      transition: all 0.3s ease-in-out;
    }
  }
}

.typping-widget {
  $dot_color: rgb(209, 209, 209);
  position: absolute;
  top: -21px;
  left: 21px;
  display: flex;
  align-items: center;

  .text {
    margin-right: 10px;
    color: rgb(162, 162, 162);
    font-size: 11px;
  }
}
</style>

<template>
  <div id="app">
    <notifications position="bottom right" />
    <router-view />
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --plyr-audio-controls-background: transparent;
  --plyr-range-fill-background: #0E142A;
  --plyr-audio-control-background-hover: #0E142A;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

body {
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  background: #F8F8F8;
  position: relative;
}

#app {
  width: 100%;
  max-width: 1700px;
  height: 100vh;
}

.vue-notification-group {
  z-index: 99999999 !important;
}
</style>
<template>
    <div class="page-wrapper">
        <div class="content">
            <loader v-if="isLoading" />
            <div class="contactus">
                <div class="info">
                    <h2>Let's Talk</h2>
                    <p>Have some big idea or brand to develop and need help? Then reach out we'd love to hear about your
                        project and provide help.</p>


                    <div class="email">
                        <h4>Email</h4>
                        <a href="mailto:info@cityradio.rw">info@cityradio.com</a>
                    </div>
                    <div class="phone">
                        <h4>Phone</h4>
                        <a href="tel:0788304596">0788304596</a> <br>
                        <a href="tel:0790134445">0790134445</a>
                    </div>
                </div>
                <div class="form">
                    <form @submit.prevent="contactUs">
                        <label>Name</label>
                        <input type="text" v-model="contactForm.name" required>
                        <label>Email</label>
                        <input type="email" v-model="contactForm.email" required>
                        <label>What service are you interested in</label>
                        <select v-model="contactForm.service" required>
                            <option value="" hidden>Select project type</option>
                            <option value="Ads">Ads</option>
                        </select>
                        <textarea v-model="contactForm.message" required></textarea>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            isLoading: false,
            viewsearch: false,
            searched: '',
            news: [],
            podcasts: [],
            jobs: [],
            contactForm: {
                name: '',
                email: '',
                service: '',
                message: ''
            }
        }
    },
    methods: {
        searchthis(searched) {
            this.viewsearch = true
            this.searched = searched
        },
        contactUs() {
            let vm = this;
            vm.$store
                .dispatch('SEND_MESSAGE', vm.contactForm)
                .then((response) => {
                    vm.isLoading = false
                    vm.$notify({
                        title: 'Important Message',
                        text: 'Message Sent Successfully'
                    })
                    vm.contactForm.name = ''
                    vm.contactForm.email = ''
                    vm.contactForm.projectType = ''
                    vm.contactForm.projectBudget = ''
                    vm.contactForm.message = ''
                })
                .catch(err => {
                    if (Array.isArray(err.response.data.message)) {
                        err.response.data.message.forEach(alert => {
                            vm.$notify({
                                type: 'error',
                                title: 'Important Message',
                                text: alert
                            })
                        })
                    } else {
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: err.response.data.message
                        })
                    }
                });
        }
    },
    mounted() {
        let vm = this
        vm.news = vm.$getAllNews()
        vm.podcasts = vm.$getAllPodcasts()
        vm.jobs = vm.$getAllJobs()
        vm.$store.state.openSidebar = false
    }
}
</script>
<style scoped lang="scss">
.content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 0 100px 0;

    &::-webkit-scrollbar {
        width: 10px;

        @media (max-width: 480px) {
            width: 5px;
        }
    }

    &::-webkit-scrollbar-thumb {
        width: 20px;
        background: #0E142A;
    }

    .contactus {
        padding: 10px 40px;
        display: flex;
        column-gap: 50px;

        @media (max-width: 768px) {
            flex-direction: column;
            row-gap: 70px;
        }

        @media (max-width: 480px) {
            padding: 10px 20px;
        }

        .info {
            p {
                font-size: 0.85rem;
                margin: 30px 0;
                line-height: 1.8rem;
            }

            .email,
            .phone {
                h4 {
                    font-weight: 600;
                    margin: 0 0 5px 0;
                }

                a {
                    font-size: 0.8rem;
                    color: #0E142A;

                    &.hover {
                        text-decoration: underline;
                    }
                }
            }

            .email {
                margin: 0 0 20px 0;

                a {
                    font-size: 0.8rem;
                    color: #0E142A;

                    &.hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .form {
            width: 100%;

            form {
                width: 100%;
                display: flex;
                flex-direction: column;

                label {
                    font-size: 0.8rem;
                }

                input {
                    all: unset;
                    border-radius: 4px;
                    padding: 4px 10px;
                    font-size: 0.8rem;
                    background: #e4e2e2;
                    margin: 4px 0;
                }

                select {
                    border: none;
                    border-radius: 4px;
                    padding: 4px 10px;
                    font-size: 0.8rem;
                    background: #e4e2e2;
                    margin: 4px 0;
                    outline: none;
                }

                textarea {
                    all: unset;
                    border: none;
                    height: 100px;
                    border-radius: 4px;
                    padding: 4px 10px;
                    font-size: 0.8rem;
                    background: #e4e2e2;
                    margin: 4px 0;
                    outline: none;
                }

                button {
                    border: none;
                    outline: none;
                    border-radius: 4px;
                    padding: 7px 10px;
                    font-size: 0.8rem;
                    color: #e4e2e2;
                    background: #0E142A;
                    cursor: pointer;
                    margin: 4px 0;
                }
            }
        }
    }
}</style>
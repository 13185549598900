<template>
    <transition name="search">
        <div class="searchcomp">
            <div class="result-close">
                <h3>Search result "{{ $store.state.searchText }}"</h3>
                <div class="icon" @click="$router.go(-1)">
                    <img src="@/assets/svg/close.svg" alt="">
                </div>
            </div>
            <!-- <label v-if="searchResultsLengths == null">No results founds</label> -->
            <div v-if="$store.state.searchResults">
                <div class="newspart">
                <h4>News ({{ $store.state.searchResults.news.length }})</h4>
                <div class="news" v-if="$store.state.searchResults.news.length > 0"> 
                    <transition-group name="list">
                        <router-link :to="{ path: '/news/' + article.id }" v-for="article in $store.state.searchResults.news" :key="article.id" @click="$emit('closesearch')">
                            <div class="article-img">
                                <img :src="assetsUrl + article.imageFileName" alt="">
                            </div>
                            <div class="description">
                                <h2 class="heading">{{ article.title }}</h2>
                                <p class="time">{{ datetime(article.createdAt) }}</p>
                                <p class="newsarticle" v-html="article.content"></p>
                            </div>
                        </router-link>
                    </transition-group>
                </div>
                <label v-else>No results found</label>
                </div>
                <div class="podcastspart">
                    <h4>Podcasts  ({{ $store.state.searchResults.podcasts.length }})</h4>
                    <div class="podcasts" v-if="$store.state.searchResults.podcasts.length">
                        <transition-group name="list">
                            <router-link class="podcast" :to="{ path: '/podcasts/' + podcast.id }" v-for="podcast in $store.state.searchResults.podcasts" :key="podcast.id">
                                <div class="article-img">
                                    <img :src="assetsUrl + podcast.imageFileName" alt="">
                                </div>
                                <div class="description">
                                    <h2 class="heading">{{ podcast.title }}</h2>
                                    <p class="newsarticle">{{ podcast.content }}</p>
                                </div>
                            </router-link>
                        </transition-group>
                    </div>
                    <label v-else>No results found</label>
                </div>
                <!-- <div class="jobspart">
                    <h4>Programs ({{ searched.programs.length }})</h4>
                    <div class="jobs" v-if="searched.programs.length">
                        <transition-group name="list">
                            <div class="program" :class="{ activeProgram: isWithinRange }">
                            <div class="program" v-for="program in searched.programs" :key="program.id">
                                <div class="dot"></div>
                                <div class="time">
                                    <label>{{ hm(program.startTime) }}</label>
                                    <span>-</span>
                                    <label>{{ hm(program.endTime) }}</label>
                                </div>
                                <h3><span>-</span>{{ program.title }}</h3>
                            </div>
                        </transition-group>
                    </div>
                    <label v-else>No results found</label>
                </div> -->
                </div>
            </div>
    </transition>
</template>
<script>
import { ASSETS_BASE_URL } from '@/store/variables'
export default{
  name: 'Results',
  data(){
    return{
        assetsUrl: ASSETS_BASE_URL,
        searchedResult: null,
        isLoading: false,
    }
  },
  methods:{
    datetime(time){
        return  time.split('T')[1].split(':')[0] + ":" + time.split('T')[1].split(':')[1] + ", " + time.split('T')[0]
    },
    playPod(audio,details){
        if(audio[0].paused){
            document.querySelectorAll('audio').forEach(aud => aud.pause())
            audio[0].play()
            details.isPlaying = true
        }else{
            document.querySelectorAll('audio').forEach(aud => aud.pause())
            audio[0].pause()
            details.isPlaying = false
            }
        },
    },
    computed:{
        searchResultsLengths(){
            // if(this.$store.searchResults === null || typeof this.$store.searchResults !== 'object'){
            //     return null;
            // }
            // else{
            //     return Object.keys(this.$store.searchResults).length;
            // }
            if(this.$store.searchResults !== null && typeof this.$store.searchResults === 'object'){
                return Object.keys(this.$store.searchResults).length;
            }
            else{
                return null;
            }
        }
    }
}
</script>
<style lang="scss">
    .searchcomp{
        position: fixed;
        overflow-y: scroll;
        height: 100%;
        position: fixed;
        left: 260px; 
        width: calc(100% - 260px);
        z-index: 9999;
        background: #f8f8f8;
        padding: 20px 50px 200px;
        top: 0px;
        @media (max-width: 1070px) {
            top: 70px;
            left: 0;
            width: 100%;
        }
        @media (max-width: 470px) {
            padding: 20px 20px 200px;
        }
        &::-webkit-scrollbar{
          width: 10px;
        }
        &::-webkit-scrollbar-thumb{
          width: 20px;
          background: #0E142A;
        }
        .result-close{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 30px 0;
            h3{
                @media (max-width: 470px) {
                    font-size: 1rem;
                }
            }
            .icon{
                width: 30px;
                height: 30px;
                position: relative;
                cursor: pointer;
                @media (max-width: 500px) {
                    width: 25px;
                    height: 25px;
                }
                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .newspart,.podcastspart,.jobspart{
            transition: .6s;
            &:not(:last-child){
                margin: 0 0 30px;
            }
            h4{
                margin: 0 0 20px 0;
                padding: 0 0 5px 0;
                border-bottom: 1px solid #0E142A;
                @media (max-width: 470px) {
                    font-size: 0.8rem;
                }
            }
            label{
                display: block;
                text-align: center;
                font-size: 0.9rem;
                color: rgb(110, 110, 110);
                width: 100%;
                @media (max-width: 470px) {
                    font-size: 0.8rem;
                }
            }
            .list-enter-active,.list-leave-active{
                transition: 0.6s;
            }
            .list-enter-from,.list-leave-to{
                opacity: 0;
                transform: translateX(20px);
            }
        }
        .news{
            display: grid;
            column-gap: 20px;
            row-gap: 40px;
            grid-template-columns: 1fr 1fr 1fr;
            @media (max-width: 840px) {
                grid-template-columns: 1fr 1fr;
              }
              @media (max-width: 550px) {
                grid-template-columns: 1fr;
              }
            a{
                text-decoration: none;
                color: #0E142A;
                .article-img{
                width: 100%;
                height: 200px;
                position: relative;
                overflow: hidden;
                border-radius: 6px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                }
                .description{
                padding: 10px 0px;
                width: 100%;
                .heading{
                    overflow: hidden;
                    font-size: 0.95rem;
                    box-orient: vertical;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                }
                .time{
                    font-size: 0.8rem;
                    color: #646464;
                    margin: 5px 0;
                }
                .newsarticle {
                    font-size: 0.8rem;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    @media (max-width: 840px) {
                        line-clamp: 3;
                        -webkit-line-clamp: 3;
                    }
                    @media (max-width: 768px){
                        max-height: 65px;
                    }
                }
                }
            }
            }
            .podcasts {
                width: 100%;
                display: grid;
                column-gap: 20px;
                row-gap: 40px;
                margin: 30px 0;
                grid-template-columns: 1fr 1fr 1fr 1fr; 
                @media (max-width: 840px) {
                    grid-template-columns: 1fr 1fr 1fr;
                }
                @media (max-width: 670px) {
                    grid-template-columns: 1fr 1fr;
                }
                @media (max-width: 465px) {
                    grid-template-columns: 1fr;
                }
                a {
                    text-decoration: none;
                    color: #0E142A;
                    .article-img {
                        width: 100%;
                        height: 200px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 6px;
                        img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                        }
                    }
                    .description {
                        padding: 10px 0px;
                        width: 100%;
                    .heading {
                        overflow: hidden;
                        font-size: 0.95rem;
                        box-orient: vertical;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        line-clamp: 1;
                        -webkit-line-clamp: 1;
                        text-overflow: ellipsis;
                        @media (max-width: 840px) {
                            line-clamp: 2;
                            -webkit-line-clamp: 2;
                        }
                    }
                    .newsarticle {
                        font-size: 0.8rem;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        @media (max-width: 840px) {
                        line-clamp: 3;
                        -webkit-line-clamp: 3;
                        }

                        @media (max-width: 768px) {
                        max-height: 65px;
                        }
                    }
                    }
                }
                }
            .jobs{
            column-gap: 20px; 
            row-gap: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 20px;
            row-gap: 30px;
            @media (max-width: 840px) {
            grid-template-columns: 1fr;
            }
            .job{
                column-gap: 20px;
                .heading{
                overflow: hidden;
                font-size: 0.95rem;
                box-orient: vertical;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                text-overflow: ellipsis;
                }
                .time{
                font-size: 0.8rem;
                color: #646464;
                margin: 5px 0;
                }
                .jobdescription{
                font-size: 0.8rem;
                overflow: hidden;
                box-orient: vertical;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                margin-bottom: 5px;
                }
                a{
                color: #E0291B;
                font-weight: 600;
                font-size: 0.9rem;
                text-decoration: none;
                padding-bottom: 1px;
                border-bottom: 1px solid #E0291B;
                }
            }
            }
    }
</style>
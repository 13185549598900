<template>
  <swiper
    @imagesReady="onImagesReady"
    :effect="'coverflow'"
    :grabCursor="true"
    :centeredSlides="true"
    :slidesPerView="3"
    :loop="true"
    :breakpoints="breaks"
    :autoplay="{
      delay: 4000,
      disableOnInteraction: false,
    }"
    :initialSlide="0"
    :coverflowEffect="{
      rotate: 50,
      stretch: 10,
      depth: 100,
      modifier: 1,
    }"
    :pagination="{ 
      clickable: true,
      dynamicBullets: true
    }"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide class="aslide" v-for="article in slides" :key="article.id">
      <img :src="assetsUrl + article.image" />
      <h2>{{ article.title }}</h2>
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import { ASSETS_BASE_URL } from '@/store/variables'

export default {
  name: 'Slider',
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
      allnews: [],
      assetsUrl: ASSETS_BASE_URL,
      breaks:{
        0:{
          slidesPerView: 1,
        },
        640:{
          slidesPerView: 3
        }
      },
      siteContents: []
    }
  },
  computed:{
    slides(){
      return this.siteContents.filter(content => content.type == "home-slider")
    }
  },
  methods:{
    onImagesReady(){
      this.$nextTick(() =>{
        this.$refs.swiper.swiper.update()
      })
    }
  },
  setup() {
    return {
      modules: [EffectCoverflow, Pagination, Autoplay],
    };
  },
  mounted(){
    this.$getAllSiteContents()
  },
};
</script>
<style lang="scss">
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 640px) {
    width: 90%;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  height: 200px;
  @media (max-width: 955px) {
    height: 170px;
  }
  @media (max-width: 640px) {
    height: 200px;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.aslide{
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: .6s;
  z-index: 1;
  padding: 20px;
  display: flex;
  align-items: flex-end;
  &::before{
    content: '';
    z-index: 2;
    background: linear-gradient(to top, #00000094, transparent);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  img{
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
  }
  &.swiper-slide-visible{
    scale: 0.9;
  }
  &.swiper-slide-active{
    scale: 1.2;
    box-shadow: 0 0px 20px rgba(0,0,0,0.2);
    @media (max-width: 640px) {
      scale: 1;
    }
    h2{
      opacity: 1;
      transform: translateY(0px);
    }
  }
  h2{
    z-index: 3;
    font-size: 1rem;
    color: #fff;
    transition: .6s;
    font-size: 1.2rem;
    overflow: hidden;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    opacity: 0;
    transform: translateY(-20px);
    @media (max-width: 955px) {
      font-size: 0.9rem;
    }
  }
}
.swiper-pagination-bullet {
  background-color: #8a8a8a;
  width: 7px;
  height: 7px;
  margin: 0 6px;
  border-radius: 50%;
}
.swiper-pagination-bullet-active {
  background-color: #0E142A;
}
</style>
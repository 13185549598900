<template>
    <swiper
      :navigation="true"
      :auto-height="true"
      :space-between="10"
      :slides-per-view="'auto'"
      :modules="modules"
      :auto-width="true"
      class="mySwiper categoryswiper"
    >
    <swiper-slide v-for="category in allcategories" :key="category.id">
        <button :class="{ 'Active': category.active }" @click="navigate(category.id)">{{ category.title }}</button>
    </swiper-slide>
    </swiper>
  </template>
  <script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";
  
  // Import Swiper styles
  import "swiper/css";
  import 'swiper/css/navigation';

  // import required modules
  import { Navigation } from 'swiper';
  
  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
      return{
        allcategories: []
      }
    },
    methods:{
      getAllCategories(){
        let vm = this;
        vm.$store
        .dispatch("GET_ALL_CATEGORIES")
        .then((response) => {
            vm.allcategories = response.data.data
            vm.allcategories.push({id: 0,title: 'All'})
            vm.allcategories.forEach(category => {
              if(category.id == 0){
                category.active = true
              }else{
                category.active = false
              }
            })
        })
        .catch(response => {
            vm.isLoading = false;  
        });
      },
      navigate(id){
        let vm = this
        vm.$emit('onnavigate',id)
        vm.allcategories.forEach(category => {
            if(category.id == id){
              category.active = true
            }else{
              category.active = false
            }
        })
      }
    },
    setup() {
      return {
        modules: [Navigation],
      };
    },
    mounted(){
      let vm = this
      vm.getAllCategories()
    }
  };
  </script>
  <style lang="scss">
  .categoryswiper {
    width: 100%;
    margin: 20px 0;
    padding: 0 50px;
    // display: flex;
    // justify-content: center;
    .swiper-slide {
        width: fit-content;
        button{
          font-size: 0.8rem;
          all: unset;
          padding: 3px 10px;
          border-radius: 4px;
          color: #0E142A;
          background: #D9D9D9;
          cursor: pointer;
          transition: .6s;
          &.Active{
            background: #0E142A;
            color: #D9D9D9;
          }
          &:hover{
            background: #0E142A;
            color: #D9D9D9;
          }
        }
      }
      .swiper-button-next {
          &::after{
              font-size: 1.2rem;
              color: #0E142A;
          }
      }
      .swiper-button-prev {
          &::after{
              font-size: 1.2rem;
              color: #0E142A;
          }
      }
  }
  </style>
import { SEARCH } from "../actions/search";
import { API_BASE_URL, SEARCH_URL } from "../variables";

import axios from "axios";

const state = {};
const mutations = {};
const getters = {};
const actions = {
    [SEARCH]: ({ commit, dispatch, getters }, payload) => {
        return axios({
            url: API_BASE_URL + SEARCH_URL,
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + getters.get_access_token
            },
            data: payload,
        });
    }

};
export default {
    mutations,
    actions,
    getters,
    state
};
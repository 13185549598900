<template>
    <div class="page-content">
        <loader v-if="isLoading" />
        <div class="allbusiesses">
            <h3 class="header">Business in Rwanda</h3>
            <div class="businesses">
                <div class="business" v-for="business in businesses" :key="business.id">
                    <div class="business-img">
                        <img :src="assetsUrl + business.image" alt="">
                    </div>
                    <h2 class="heading">{{ business.title }}</h2>
                    <a :href="business.link" target="_blank">Visit Now</a>
                    <p class="businessdescription">{{ business.subTitle }}</p>
                </div>
            </div>
        </div>
        <fixedbar />
    </div>
</template>
  
<script>
import { ASSETS_BASE_URL } from '@/store/variables'
export default {
    name: 'BusinessInRwanda',
    data() {
        return {
            isLoading: false,
            assetsUrl: ASSETS_BASE_URL,
            siteContents: []
        }
    },
    methods: {
    },
    computed:{
        businesses(){
            return this.siteContents.filter(content => content.type == "business")
        }
    },
    mounted() {
        let vm = this
        vm.$store.state.openSidebar = false
        vm.$getAllSiteContents()
    }
}
</script>
<style scoped lang="scss">
.page-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 0 100px 0;

    &::-webkit-scrollbar {
        width: 10px;

        @media (max-width: 480px) {
            width: 5px;
        }
    }

    &::-webkit-scrollbar-thumb {
        width: 20px;
        background: #0E142A;
    }

    .allbusiesses {
        padding: 10px 40px;

        @media (max-width: 400px) {
            padding: 10px 10px;
        }
        .header {
            padding: 10px 0px;
            border-bottom: 3px solid #E0291B;
            h2 {
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .businesses {
            display: grid;
            column-gap: 40px;
            row-gap: 50px;
            margin: 30px 0;
            grid-template-columns: 1fr 1fr 1fr 1fr;

            @media (max-width: 740px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 550px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 400px) {
                grid-template-columns: 1fr;
            }

            .business {
                color: #0E142A;
                display: flex;
                flex-direction: column;
                align-items: center;

                .business-img {
                    width: 120px;
                    height: 120px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .heading {
                    overflow: hidden;
                    font-size: 0.9rem;
                    margin: 5px 0;
                    font-weight: 500;
                    box-orient: vertical;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                }

                a {
                    font-size: 0.8rem;
                    margin: 5px 0;
                    text-decoration: none;
                    border-radius: 5px;
                    border: 1px solid #0E142A;
                    padding: 5px 10px;
                    transition: .6s;
                    color: #fff;
                    background: #0E142A;

                    &:hover {
                        color: #0E142A;
                        background: transparent;
                    }
                }

                .businessdescription {
                    font-size: 0.8rem;
                    text-align: center;
                    overflow: hidden;
                    box-orient: vertical;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    line-clamp: 2;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>
<template>
    <div class="page-wrapper">
        <Modal v-if="isDelete"  @click.self="close()">
            <div class="delete">
                <h2>Are you sure you want to Delete this ?</h2>
                <div class="buttons">
                    <button class="cancel" @click="close()">Cancel</button>
                    <button class="del" @click="confirmDelete()">Delete</button>
                </div>
            </div>
        </Modal>
        <Modal v-if="viewadd" @click.self="close">
            <form v-if="viewaddCategory" @submit.prevent="createCategory()">
                <div class="name-close">
                    <h1>Add Category</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Name</label>
                    <input class="input" type="text" v-model="categoryForm.title" required>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Create</button>
                </div>
            </form>
            <form class="newsArticle" v-else @submit.prevent="createArticle()">
                <div class="name-close">
                    <h1>Add new Article</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <div>
                        <label>Title</label>
                        <textarea v-model="articleForm.title" required></textarea>
                        <label>Select Author</label>
                        <input v-model="articleForm.author" class="input" required>
                        <label>Category</label>
                        <select required v-model="articleForm.categoryId">
                            <option value="" hidden>Select Category</option>
                            <option :value="category.id" v-for="category in allcategories" :key="category.id">{{ category.title }}</option>
                        </select>
                        <label>Summary</label>
                        <textarea v-model="articleForm.summary"></textarea>
                        <label>Image</label>
                        <uploader class="input" type="image/*" @onFileUploaded="uploadedImg($event)" v-model="articleForm.imageFileName" />
                    </div>
                    <div>
                        <label>Body</label>
                        <VueEditor v-model="articleForm.content" />
                    </div>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Create</button>
                </div>
            </form>
        </Modal>
        <Modal v-if="viewmodal" @click.self="close">
            <form v-if="modalType == 'editcategory'" @submit.prevent="updateCategory()">
                <div class="name-close">
                    <h1>Add Category</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Name</label>
                    <input class="input" type="text" v-model="editCategoryForm.title" required>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Create</button>
                </div>
            </form>
            <form class="newsArticle" v-else @submit.prevent="updateArticle()">
                <div class="name-close">
                    <h1>Add new Article</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <div>
                        <label>Title</label>
                        <textarea v-model="editArticleForm.title" required></textarea>
                        <label>Select Author</label>
                        <input v-model="editArticleForm.author" class="input" required>
                        <label>Category</label>
                        <div v-if="editArticleForm.category">
                            <select required v-model="editArticleForm.category.id">
                                <option value="" hidden>Select Category</option>
                                <option :value="category.id" v-for="category in allcategories" :key="category.id">{{ category.title }}</option>
                            </select>
                        </div>
                        <div v-else>
                            <select required v-model="editArticleForm.categoryId">
                                <option value="" hidden>Select Category</option>
                                <option :value="category.id" v-for="category in allcategories" :key="category.id">{{ category.title }}</option>
                            </select>
                        </div>
                        <label>Summary</label>
                        <textarea v-model="editArticleForm.summary"></textarea>
                        <label>Image</label>
                        <uploader class="input" type="image/*" @onFileUploaded="uploadedImg($event,'edit')" v-model="editArticleForm.imageFileName" />
                    </div>
                    <div>
                        <label>Body</label>
                        <VueEditor v-model="editArticleForm.content" style="color: #0E142A; font-size: 0.8rem;" />
                    </div>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Update</button>
                </div>
            </form>
        </Modal>
        <van-tabs v-model:active="activeName">
            <van-tab title="Categories" name="categories">
                <div class="header">
                    <button @click="Add('category')">Add new Category</button>
                </div>
                <div v-if="!allcategories.length" class="empty">No Categories available!</div>
                <div v-else class="table category">
                    <div class="headings">
                        <h2>Category Name</h2>
                        <h2></h2>
                    </div>
                    <div class="details" v-for="category in allcategories" :key="category.id">
                        <h3>{{ category.title }}</h3>
                        <div class="edit">
                            <div class="icon">
                                <img src="@/assets/svg/options.svg" @click="category.options = !category.options" alt="">
                                <div class="options" :class="{ active: category.options }">
                                    <div  @click="viewModal('editcategory',category)">
                                        <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                        Edit
                                    </div>
                                    <div  @click="$delete(category, 'category')">
                                        <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                        Delete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="News" name="news">
                <div class="header">
                    <button @click="Add('article')">Add new Article</button>
                </div>
                <div v-if="!allnews.length" class="empty">No Articles available!</div>
                <div v-else class="table news">
                    <div class="headings">
                        <h2>Heading</h2>
                        <h2>preview</h2>
                        <h2>descrption</h2>
                        <h2>time</h2>
                        <h2></h2>
                    </div>
                    <div class="details" v-for="article in allnews" :key="article.id">
                        <div class="ellipsis"><h4>{{ article.title }}</h4></div>
                        <div class="img"><img :src="assetsUrl + article.imageFileName"></div>
                        <div class="ellipsis"><h4 v-html="article.content"></h4></div>
                        <div class="ellipsis"><h4>{{ datetime(article.createdAt) }}</h4></div>
                        <div class="edit">
                            <div class="icon">
                                <img src="@/assets/svg/options.svg" @click="article.options = !article.options" alt="">
                                <div class="options" :class="{ active: article.options }">
                                    <div  @click="viewModal('editarticle',article)">
                                        <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                        Edit
                                    </div>
                                    <div  @click="$delete(article)">
                                        <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                        Delete
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
  
<script>
import { VueEditor } from "vue3-editor";
import { API_BASE_URL, CREATE_UPLOAD_URL, ASSETS_BASE_URL } from '@/store/variables'
export default {
    name: 'Admin-News',
    components: { VueEditor },
    data() {
        return {
            assetsUrl: ASSETS_BASE_URL,
            activeName: 'categories',
            viewadd: false,
            viewaddCategory: false,
            viewaddArticle: false,
            categoryForm: { title: '' },
            isLoading: false,
            isDelete: false,
            deleteType: '',
            viewmodal: false,
            editCategoryForm: null,
            editArticleForm: null,
            modalType: null,
            deleteId: '',
            articleForm: {
                title: "",
                author: "",
                summary: "",
                content: "",
                imageFileName: "",
                categoryId: "",
            },
            allcategories: [],
            allnews: [],
        }
    },
    methods: {
        Add(content){
            let vm = this
            vm.viewadd = true
            if(content == 'article'){
                vm.viewaddArticle = true
            }else{
                vm.viewaddCategory = true
            }
        },
        viewModal(type,content){
            let vm = this
            vm.modalType = type
            type == "editcategory" ? ((content.options = false), (vm.editCategoryForm = {...content})) : ((content.options = false), (vm.editArticleForm = {...content, categoryId: ""}))
            vm.viewmodal = true
        },
        close(){
            let vm = this
            vm.viewaddCategory = false
            vm.viewaddArticle = false
            vm.isDelete = false
            vm.deleteId = ''
            vm.deleteType = ''

            vm.viewmodal = false
            vm.modalType = null
            vm.editCategoryForm = null
            vm.editArticleForm = null

            vm.viewadd = false
            vm.categoryForm.title = ""
            vm.articleForm.title = ""
            vm.articleForm.imageFileName = ""
            vm.articleForm.summary = ""
            vm.articleForm.slug = ""
            vm.articleForm.content = ""
            vm.articleForm.categoryId = ""
            vm.articleForm.author
        },
        uploadedImg(load,type) {
            if(type == 'edit'){
                this.editArticleForm.imageFileName = load.fileName
            }else{
                this.articleForm.imageFileName = load.fileName
            }
        },
        datetime(time){
            return  time.split('T')[1].split(':')[0] + ":" + time.split('T')[1].split(':')[1] + ", " + time.split('T')[0]
        },
        confirmDelete(){
            let vm = this;
            if(vm.deleteType == 'category'){
                vm.$store
                .dispatch("DELETE_CATEGORY", vm.deleteId)
                .then((response) => {
                    vm.close()
                    vm.getAllCategories()
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: response.data.message
                    })
                })
            }else{
                vm.$store
                .dispatch("DELETE_ARTICLE", vm.deleteId)
                .then((response) => {
                    vm.close()
                    vm.getAllNews()
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: response.data.message
                    })
                })
            }
        },
        createCategory(){
            let vm = this;
            vm.$store
            .dispatch('CREATE_CATEGORY', vm.categoryForm)
            .then((response) => {
                vm.viewadd = false
                vm.isLoading = false
                vm.$notify({
                    title: 'Important Message',
                    text: 'Added Successfully'
                })
                vm.close();
                vm.getAllCategories();
            })
            .catch(err => {
                vm.$notify({
                    type: 'error',
                    title: 'Important Message',
                    text: err.response.data.message
                })
            });
        },
        updateCategory(){
            let vm = this;
            vm.$store
            .dispatch('UPDATE_CATEGORY', vm.editCategoryForm)
            .then((response) => {
                vm.viewadd = false
                vm.isLoading = false
                vm.$notify({
                    title: 'Important Message',
                    text: 'Updated Successfully'
                })
                vm.close();
                vm.getAllCategories();
            })
            .catch(err => {
                vm.$notify({
                    type: 'error',
                    title: 'Important Message',
                    text: err.response.data.message
                })
            });  
        },
        createArticle(){
            let vm = this;
            vm.$store
            .dispatch('CREATE_ARTICLE', vm.articleForm)
            .then((response) => {
                vm.viewadd = false
                vm.$notify({
                    title: 'Important Message',
                    text: 'Created Successfully'
                })
                vm.close();
                vm.getAllNews();
            })
            .catch(err => {
                vm.$notify({
                    type: 'error',
                    title: 'Important Message',
                    text: err.response.data.message
                })
            });
        },
        updateArticle(){
            let vm = this;
            if(vm.editArticleForm.category){
                vm.editArticleForm.categoryId = vm.editArticleForm.category.id
            }
            vm.$store
            .dispatch('UPDATE_ARTICLE', vm.editArticleForm)
            .then((response) => {
                vm.viewadd = false
                vm.$notify({
                    title: 'Important Message',
                    text: 'Created Successfully'
                })
                vm.close();
                vm.getAllNews();
            })
            .catch(err => {
                vm.$notify({
                    type: 'error',
                    title: 'Important Message',
                    text: err.response.data.message
                })
            });
        },
        getAllCategories(){
            let vm = this;
            vm.isLoading = true
            vm.$store
            .dispatch("GET_ALL_CATEGORIES")
            .then((response) => {
                vm.isLoading = false
                vm.allcategories = response.data.data
                vm.allcategories.forEach(category => {
                    category.options = false
                })
            })
            .catch(response => {
                vm.isLoading = false;  
            });
        },
        getAllNews(){
            this.$store
            .dispatch("GET_ALL_ARTICLES")
            .then(resp =>{
                this.allnews = resp.data.data
            })
            .catch(err => console.log(err))
        }
    },
    mounted() {
        let vm = this
        vm.getAllCategories()
        vm.getAllNews()
    }
}
</script>
<style scoped lang="scss">
*{
    color: #0E142A;
}
.page-wrapper {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin-left: 250px;
    overflow-y: auto;
    @media (max-width: 1070px) {
        margin: 0px;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 100%;
      background: #0E142A;
    }
    .empty{
        text-align: center;
        font-size: 0.85rem;
        color: #666666;
    }
    .modal{
        form{
            &.newsArticle{
                width: 95%;
                .inputs{
                    display: grid;
                    column-gap: 30px;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
    .header{
        padding: 10px 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        button {
            outline: none;
            border: 1px solid #0e142a60;
            border-radius: 7px;
            position: relative;
            overflow: hidden;
            border: none;
            font-size: 0.85rem;
            padding: 8px 25px;
            background: #0E142A;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .table{
        width: 100%;
        padding: 10px 30px;
        .headings,.details{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .headings{
            padding: 7px 4px;
            h2{
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.85rem;
            }
        }
        .details{
            margin: 10px 0;
            padding: 7px 4px;
            border-radius: 5px;
            h3{
                padding: 0 0 0 5px;
                font-size: 0.85rem;
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .edit{
                .icon{
                    margin: auto;
                    position: relative;
                    width: 20px;
                    height: 20px;
                    img{
                        width: 100%;
                        height: 100%;
                        top: 50%;
                        left: 50%; 
                        position: absolute;
                        transform: translate(-50%,-50%);
                        cursor: pointer;
                    }
                    .options{
                        display: none;
                        column-gap: 30px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0.8rem;
                        right: 100%;
                        z-index: 2;
                        padding: 5px 14px;
                        &.active{
                            display: flex;
                        }
                        div{
                            cursor: pointer;
                            display: flex;
                            column-gap: 7px;
                            border-radius: 5px;
                            padding: 3px 14px;
                            background: #ccc;
                        }
                    }
                }
            }
        }
        &.news{
            .headings,.details{
                width: 100%;
                display: grid;
                column-gap: 30px;
                grid-template-columns: 20% 25% 25% 15% 1fr;
            }
            .details{
                margin: 10px 0;
                padding: 7px 4px;
                border-radius: 5px;
                .ellipsis{
                    width: 100%;
                    position: relative;
                    h4{
                        overflow: hidden;
                        font-size: 0.8rem;
                        box-orient: vertical;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                        line-clamp: 5;
                        line-height: 1.7rem;
                        color: #0e142abe;
                        font-weight: 500;
                        -webkit-line-clamp: 5;
                        text-overflow: ellipsis;
                    }
                }
                .img{
                    width: 300px;
                    height: 150px;
                    position: relative;
                    overflow: hidden;
                    border-radius: 7px;
                    img{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .edit{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon{
                        position: relative;
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        img{
                            width: 100%;
                            height: 100%;
                            top: 50%;
                            left: 50%; 
                            position: absolute;
                            transform: translate(-50%,-50%);
                        }
                    }
                }
            }
        }
    }
}


.quillWrapper{
    max-height: 370px;
    overflow-y: auto;
    font-size: 0.8rem;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 100%;
      background: #0E142A;
    }
}
</style>
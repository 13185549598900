<template>
  <div class="page-content">
    <loader v-if="isLoading" />
    <div class="ads">
      <div class="ad" v-for="ad in ads.slice(1,3)" :key="ad.id">
        <img :src="assetsUrl + ad.image" alt="">
      </div>
    </div>
    <slider />
    <div class="tabs">
      <div class="tab" :class="{ activeTab: myTab == 'recentNews' }" @click="myTab = 'recentNews'"><label>Recent
          news</label></div>
      <div class="tab" :class="{ activeTab: myTab == 'recentPodcast' }" @click="myTab = 'recentPodcast'"><label>Recent
          podcast</label></div>
    </div>
    <div class="tabcontents">
      <transition-group name="tabscontent">
        <div v-if="myTab == 'recentNews'">
          <div v-if="allnews.length" class="news">
            <router-link :to="{ path: '/news/' + article.id }" v-for="article in allnews" :key="article.id">
              <div class="article-img">
                <img :src="assetsUrl + article.imageFileName" alt="">
              </div>
              <div class="description">
                <h2 class="heading">{{ article.title }}</h2>
                <p class="time">{{ article.time }}</p>
                <div class="newsarticle" v-html="article.content"></div>
              </div>
            </router-link>
          </div>
          <div v-else class="empty">No News Articles Available!</div>
        </div>
        <div class="allpodcasts" v-else-if="myTab == 'recentPodcast'">
          <div class="podcasts" v-if="allpodcasts.length">
              <router-link class="podcast" :to="{ path: '/podcasts/' + podcast.id }" v-for="podcast in allpodcasts" :key="podcast.id">
                  <div class="article-img">
                      <img :src="assetsUrl + podcast.imageFileName" alt="">
                  </div>
                  <div class="description">
                      <h2 class="heading">{{ podcast.title }}</h2>
                      <p class="newsarticle">{{ podcast.content }}</p>
                  </div>
              </router-link>
          </div>
          <div v-else class="empty">No Podcasts Available!</div>
        </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import { ASSETS_BASE_URL } from '@/store/variables'
export default {
  name: 'Home',
  data() {
    return {
      assetsUrl: ASSETS_BASE_URL,
      myTab: 'recentNews',
      isLoading: false,
      viewsearch: false,
      searched: '',
      allnews: [],
      allpodcasts: [],
      siteContents: []
    }
  },
  computed:{
    ads(){
      return this.siteContents.filter(content => content.type == "ads")
    }
  },
  methods: {
    urlHyphenate(title) {
      let url = title.trim()
      return url.replace(/\s+/g, '-').toLowerCase()
    },
    searchthis(searched) {
      this.viewsearch = true
      this.searched = searched
    },
    playPod(audio) {
      if (audio[0].paused) {
        document.querySelectorAll('audio').forEach(aud => aud.pause())
        audio[0].play()
      } else {
        document.querySelectorAll('audio').forEach(aud => aud.pause())
        audio[0].pause()
      }
    }
  },
  mounted() {
    let vm = this
    vm.$getAllRecentPodcasts()
    vm.$getAllNews()
    vm.$getAllSiteContents()
    vm.$store.state.openSidebar = false
  }
}
</script>
<style scoped lang="scss">
.page-content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 0 0 100px 0;
  
  &::-webkit-scrollbar {
    width: 10px;

    @media (max-width: 480px) {
      width: 5px;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 20px;
    background: #0E142A;
  }

  .search-enter-active,
  .search-leave-active {
    transition: .5s;
  }

  .search-enter-from,
  .search-leave-to {
    transform: translateY(20px);
    opacity: 0;
  }

  .ads {
    display: flex;
    column-gap: 50px;
    padding: 0 40px;
    margin: 30px 0;

    @media (max-width: 550px) {
      display: none;
    }

    .ad {
      width: 100%;
      height: 150px;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      background-color: #646464;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
      }
    }
  }

  .tabs {
    padding: 10px 40px;
    display: flex;
    align-items: center;
    column-gap: 40px;

    .tab {
      padding-bottom: 5px;
      cursor: pointer;

      label {
        cursor: pointer;
        font-size: 0.9rem;
        color: #0E142A;
      }

      &.activeTab {
        border-bottom: 2px solid #E0291B;
      }
    }
  }

  .tabcontents {
    padding: 10px 40px;

    @media (max-width: 450px) {
      padding: 10px 20px;
    }

    .tabscontent-enter-active,
    .tabscontent-leave-active {
      transition: .5s;
    }

    .tabscontent-enter-from,
    .tabscontent-leave-to {
      transform: translateY(20px);
      opacity: 0;
    }

    .empty {
      width: 100%;
      font-size: 0.8rem;
      color: #646464;
      text-align: center;
      position: absolute;
    }

    .news {
      display: grid;
      column-gap: 20px;
      row-gap: 40px;
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: 840px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 550px) {
        grid-template-columns: 1fr;
      }

      a {
        text-decoration: none;
        color: #0E142A;

        .article-img {
          width: 100%;
          height: 200px;
          position: relative;
          overflow: hidden;
          border-radius: 6px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .description {
          padding: 10px 0px;
          width: 100%;
          border: 1px solid salmo;

          .heading {
            overflow: hidden;
            font-size: 0.95rem;
            box-orient: vertical;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;

            @media (max-width: 840px) {
              line-clamp: 2;
              -webkit-line-clamp: 2;
            }
          }

          .time {
            font-size: 0.8rem;
            color: #646464;
            margin: 5px 0;
          }

          .newsarticle {
            font-size: 0.8rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;

            @media (max-width: 768px) {
              max-height: 45px;
            }
          }
        }
      }
    }

    .podcasts {
      width: 100%;
      display: grid;
      column-gap: 20px;
      row-gap: 40px;
      margin: 30px 0;
      grid-template-columns: 1fr 1fr 1fr 1fr; 
      @media (max-width: 840px) {
          grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: 670px) {
          grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 465px) {
          grid-template-columns: 1fr;
      }
      a {
          text-decoration: none;
          color: #0E142A;
          .article-img {
            width: 100%;
            height: 200px;
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: top;
            }
          }
          .description {
            padding: 10px 0px;
            width: 100%;
          .heading {
            overflow: hidden;
            font-size: 0.95rem;
            box-orient: vertical;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            @media (max-width: 840px) {
                line-clamp: 2;
                -webkit-line-clamp: 2;
            }
          }
          .newsarticle {
            font-size: 0.8rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            @media (max-width: 840px) {
              line-clamp: 3;
              -webkit-line-clamp: 3;
            }

            @media (max-width: 768px) {
              max-height: 65px;
            }
          }
        }
      }
    }
  }
}
</style>
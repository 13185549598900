<template>
    <div class="page-wrapper">
        <Modal v-if="isDelete"  @click.self="close()">
            <div class="delete">
                <h2>Are you sure you want to Delete this Site Content?</h2>
                <div class="buttons">
                    <button class="cancel" @click="close()">Cancel</button>
                    <button class="del" @click="confirmDelete()">Delete</button>
                </div>
            </div>
        </Modal>
        <Modal v-if="viewmodal" @click.self="close()">
            <form @submit.prevent="createContent" v-if="modalType == 'add'">
                <div class="name-close">
                    <h1>Add a Content</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Type</label>
                    <select v-model="contentForm.type">
                        <option value="" hidden>Select Type</option>
                        <option value="home-slider">Home Slider</option>
                        <option value="ads">Ads</option>
                        <option value="visitrwanda">Visit Rwanda</option>
                        <option value="business">Business</option>
                    </select>
                    <label>Title</label>
                    <input type="text" class="input" v-model="contentForm.title" required>
                    <label>SubTitle</label>
                    <input type="text" class="input" v-model="contentForm.subTitle">
                    <label>Link</label>
                    <input type="text" class="input" v-model="contentForm.link">
                    <label>Image</label>
                    <uploader class="input" type="image/*" @onFileUploaded="uploadedImg($event)" v-model="contentForm.image" />
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Create</button>
                </div>
            </form>
            <form @submit.prevent="updateContent" v-else>
                <div class="name-close">
                    <h1>Create Update</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Type</label>
                    <select v-model="editContentForm.type">
                        <option value="" hidden>Select Type</option>
                        <option value="home-slider">Home Slider</option>
                        <option value="ads">Ads</option>
                        <option value="visitrwanda">Visit Rwanda</option>
                        <option value="business">Business</option>
                    </select>
                    <label>Title</label>
                    <input type="text" class="input" v-model="editContentForm.title" required>
                    <label>SubTitle</label>
                    <input type="text" class="input" v-model="editContentForm.subTitle" required>
                    <label>Link</label>
                    <input type="text" class="input" v-model="editContentForm.link" required>
                    <label>Image</label>
                    <uploader type="image/*" @onFileUploaded="uploadedImg($event)" v-model="editContentForm.image" />
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Update</button>
                </div>
            </form>
        </Modal>
        <div class="header">
            <button @click="viewModal('add')">Add a Site Content</button>
        </div>
        <div class="allcontents">
            <h4>ADS</h4>
            <div v-if="!ads.length" class="empty">No Ads available!</div>
            <div v-else class="table">
                <div class="headings">
                    <h2>title</h2>
                    <h2>Cover</h2>
                    <h2>Sub-Title</h2>
                    <h2></h2>
                </div>
                <div class="details" v-for="ad in ads" :key="ad.id">
                    <h3>{{ ad.title }}</h3>
                    <div class="img"><img :src="assetsUrl + ad.image"></div>
                    <h3>{{ ad.subTitle }}</h3>
                    <div class="edit">
                        <div class="icon">
                            <img src="@/assets/svg/options.svg" @click="ad.options = !ad.options" alt="">
                            <div class="options" :class="{ active: ad.options }">
                                <div @click="viewModal('edit', ad)">
                                    <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                    Edit
                                </div>
                                <div @click="$delete(ad)">
                                    <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                    Delete
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4>SLIDES</h4>
            <div v-if="!slides.length" class="empty">No Slides available!</div>
            <div v-else class="table">
                <div class="headings">
                    <h2>title</h2>
                    <h2>Cover</h2>
                    <h2>Sub-Title</h2>
                    <h2></h2>
                </div>
                <div class="details" v-for="slide in slides" :key="slide.id">
                    <h3>{{ slide.title }}</h3>
                    <div class="img"><img :src="assetsUrl + slide.image"></div>
                    <h3>{{ slide.subTitle }}</h3>
                    <div class="edit">
                        <div class="icon">
                            <img src="@/assets/svg/options.svg" @click="slide.options = !slide.options" alt="">
                            <div class="options" :class="{ active: slide.options }">
                                <div @click="viewModal('edit', slide)">
                                    <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                    Edit
                                </div>
                                <div @click="$delete(slide)">
                                    <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                    Delete
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4>BUSINESS IN RWANDA</h4>
            <div v-if="!businesses.length" class="empty">No businesses available!</div>
            <div v-else class="table">
                <div class="headings">
                    <h2>title</h2>
                    <h2>Cover</h2>
                    <h2>Sub-Title</h2>
                    <h2></h2>
                </div>
                <div class="details" v-for="business in businesses" :key="business.id">
                    <h3>{{ business.title }}</h3>
                    <div class="img"><img :src="assetsUrl + business.image"></div>
                    <h3>{{ business.subTitle }}</h3>
                    <div class="edit">
                        <div class="icon">
                            <img src="@/assets/svg/options.svg" @click="business.options = !business.options" alt="">
                            <div class="options" :class="{ active: business.options }">
                                <div @click="viewModal('edit', business)">
                                    <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                    Edit
                                </div>
                                <div @click="$delete(business)">
                                    <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                    Delete
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4>VISIT RWANDA</h4>
            <div v-if="!visits.length" class="empty">Not available!</div>
            <div v-else class="table">
                <div class="headings">
                    <h2>title</h2>
                    <h2>Cover</h2>
                    <h2>Sub-Title</h2>
                    <h2></h2>
                </div>
                <div class="details" v-for="visit in visits" :key="visit.id">
                    <h3>{{ visit.title }}</h3>
                    <div class="img"><img :src="assetsUrl + visit.image"></div>
                    <h3>{{ visit.description }}</h3>
                    <div class="edit">
                        <div class="icon">
                            <img src="@/assets/svg/options.svg" @click="visit.options = !visit.options" alt="">
                            <div class="options" :class="{ active: visit.options }">
                                <div @click="viewModal('edit', visit)">
                                    <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                    Edit
                                </div>
                                <div @click="$delete(visit)">
                                    <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                    Delete
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ASSETS_BASE_URL } from '@/store/variables'
export default {
    name: 'Admin-Site',
    data() {
        return {
            isDelete: false,
            viewmodal: false,
            editContentForm: null,
            modalType: null,
            deleteId: '',
            allcontents: [],
            assetsUrl: ASSETS_BASE_URL,
            siteContents: [],
            editContentForm: null,
            contentForm:{
                title: '',
                subTitle: '',
                link: '#',
                image: '',
                type: '',
            },
        }
    },
    computed:{
        businesses(){
            return this.siteContents.filter(content => content.type == "business")
        },
        slides(){
            return this.siteContents.filter(content => content.type == "home-slider")
        },
        ads(){
            return this.siteContents.filter(content => content.type == "ads")
        },
        visits(){
            return this.siteContents.filter(content => content.type == "visitrwanda")
        },
    },
    methods: {
        viewModal(type,content){
            let vm = this
            vm.modalType = type
            if (type === "edit") { 
                content.options = false;
                vm.editContentForm = {...content};
            }
            vm.viewmodal = true
        },
        close(){
            let vm = this
            vm.viewadd = false
            vm.isDelete = false
            vm.deleteId = ''

            vm.viewmodal = false
            vm.modalType = null
            vm.editContentForm = null

            vm.contentForm.title = ''
            vm.contentForm.subTitle = ''
            vm.contentForm.link = ''
            vm.contentForm.image = ''
            vm.contentForm.type = ''
        },
        getAllSiteContents(){
            let vm = this;
            vm.isLoading = true
            vm.$store
            .dispatch("GET_ALL_SITE_CONTENTS")
            .then((response) => {
                vm.isLoading = false
                vm.allcontents = response.data.data
                vm.allcontents.forEach(program => {
                    program.options = false
                })
            })
            .catch(response => {
                vm.isLoading = false;  
            });
        },
        uploadedImg(load,type) {
            if(type == 'edit'){
                this.editContentForm.image = load.fileName
            }else{
                this.contentForm.image = load.fileName
            }
        },
        createContent(){
            let vm = this
            vm.$store
            .dispatch('CREATE_SITE_CONTENT', vm.contentForm)
            .then((response) => {
                vm.$notify({
                    title: 'Important Message',
                    text: "Content created successfully"
                })
                vm.$getAllSiteContents()
                vm.close()
            })
            .catch(err => {
                if(Array.isArray(err.response.data.message)){
                    err.response.data.message.forEach(alert =>{
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: alert
                        })
                    })
                }else{
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: err.response.data.message
                    })
                }
            });
        },
        updateContent(){
            let vm = this;
            vm.$store
            .dispatch('UPDATE_SITE_CONTENT', vm.editContentForm)
            .then((response) => {
                vm.viewadd = false
                vm.isLoading = false
                vm.$notify({
                    title: 'Important Message',
                    text: 'Updated Successfully'
                })
                vm.close()
                vm.$getAllSiteContents()
            })
            .catch(err => {
                if(Array.isArray(err.response.data.message)){
                    err.response.data.message.forEach(alert =>{
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: alert
                        })
                    })
                }else{
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: err.response.data.message
                    })
                }
            });
        },
        confirmDelete(){
            let vm = this;
            vm.$store
            .dispatch("DELETE_SITE_CONTENT", vm.deleteId)
            .then((response) => {
                vm.close()
                vm.$getAllSiteContents()
                vm.$notify({
                    type: 'error',
                    title: 'Important Message',
                    text: response.data.message
                })
            })
        },
    },
    mounted() {
        let vm = this
        vm.$getAllSiteContents()
    }
}
</script>
<style scoped lang="scss">
.page-wrapper {
    position: relative;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin-left: 260px;
    overflow-y: auto;
    @media (max-width: 1070px) {
        margin: 0px;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      width: 100%;
      background: #0E142A;
    }
    .allcontents{
        padding: 0 10px;
    }
    .header{
        padding: 10px 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        button {
            outline: none;
            border: 1px solid #0e142a60;
            border-radius: 7px;
            position: relative;
            overflow: hidden;
            border: none;
            font-size: 0.85rem;
            padding: 8px 25px;
            background: #0E142A;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .empty{
        margin: 30px 0;
        text-align: center;
        font-size: 0.85rem;
        color: #666666;
    }
    .days{
        display: flex;
        justify-content: space-around;
        margin: 10px 0;
        button {
            outline: none;
            border: 1px solid #0e142a13;
            border-radius: 7px;
            position: relative;
            overflow: hidden;
            font-size: 0.85rem;
            padding: 5px 25px;
            color: #0E142A;
            font-weight: 600;
            cursor: pointer;
            &.active{
                background: #0E142A;
                color: #fff;
            }
        }
    }
    .table {
        width: 100%;
        padding: 10px 0px;

        .headings,
        .details {
            width: 100%;
            display: grid;
            grid-template-columns: 0.8fr 1fr 1fr 1fr;
        }

        .headings {
            padding: 7px 10px;
            background: #ccc;
            border-radius: 5px;
            h2 {
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.85rem;
            }
        }

        .details {
            margin: 10px 0;
            padding: 7px 4px;
            border-radius: 5px;

            // &:nth-child(even) {
            //     background: #0e142a7e;

            //     h3 {
            //         color: #fff;
            //     }
            // }

            h3 {
                padding: 0 0 0 5px;
                font-size: 0.85rem;
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .img{
                width: 280px;
                height: 130px;
                position: relative;
                overflow: hidden;
                border-radius: 7px;
                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
            .edit{
                .icon{
                    margin: auto;
                    position: relative;
                    width: 20px;
                    height: 20px;
                    img{
                        width: 100%;
                        height: 100%;
                        top: 50%;
                        left: 50%; 
                        position: absolute;
                        transform: translate(-50%,-50%);
                        cursor: pointer;
                    }
                    .options{
                        display: none;
                        column-gap: 30px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0.8rem;
                        right: 100%;
                        z-index: 2;
                        padding: 5px 14px;
                        &.active{
                            display: flex;
                        }
                        div{
                            cursor: pointer;
                            display: flex;
                            column-gap: 7px;
                            border-radius: 5px;
                            padding: 3px 14px;
                            background: #ccc;
                        }
                    }
                }
            }
        }
    }
}
</style>
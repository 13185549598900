<template>
    <div class="page-wrapper">
        <Modal v-if="isDelete"  @click.self="close()">
            <div class="delete">
                <h2>Are you sure you want to Delete this User?</h2>
                <div class="buttons">
                    <button class="cancel" @click="close()">Cancel</button>
                    <button class="del" @click="confirmDelete()">Delete</button>
                </div>
            </div>
        </Modal>
        <Modal v-if="viewmodal" @click.self="close()">
            <form @submit.prevent="createUser" v-if="modalType == 'add'">
                <div class="name-close">
                    <h1>Add User</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <div class="flex">
                        <div>
                            <label>First Name</label>
                            <input type="text" pattern="[A-Za-z]+" class="input" v-model="userForm.firstName" required>
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input type="text" pattern="[A-Za-z]+" class="input" v-model="userForm.lastName" required>
                        </div>
                    </div>
                    <label>Email</label>
                    <input type="email" v-model="userForm.email" class="input" required>
                    <label>Role</label>
                    <select v-model="userForm.role" required>
                        <option value="" selected hidden>Select Role</option>
                        <option value="Admin">Admin</option>
                    </select>
                    <label>Password</label>
                    <input type="password" v-model="userForm.password" class="input"  required>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Add</button>
                </div>
            </form>
            <form @submit.prevent="updateUser" v-else>
                <div class="name-close">
                    <h1>Edit User</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <div class="flex">
                        <div>
                            <label>First Name</label>
                            <input type="text" pattern="[A-Za-z]+" class="input"  v-model="editUserForm.firstName" required>
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input type="text" pattern="[A-Za-z]+" class="input"  v-model="editUserForm.lastName" required>
                        </div>
                    </div>
                    <label>Email</label>
                    <input type="email" v-model="editUserForm.email" class="input"  required>
                    <label>Role</label>
                    <select v-model="editUserForm.role" required>
                        <option value="" selected hidden>Select Role</option>
                        <option value="Admin">Admin</option>
                    </select>
                    <label>Password</label>
                    <input type="password" v-model="editUserForm.password" class="input"  required>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Update</button>
                </div>
            </form>
        </Modal>
        <div class="header">
            <button @click="viewModal('add')">Add new User</button>
        </div>
        <div v-if="!allstaff.length" class="empty">No Users available!</div>
        <div v-else class="table">
            <div class="headings">
                <h2>Names</h2>
                <h2>Email</h2>
                <h2>Role</h2>
                <h2></h2>
            </div>
            <div class="details" v-for="user in allstaff" :key="user.id">
                <h3>{{ user.firstName }} {{ user.lastName }}</h3>
                <h3>{{ user.email }}</h3>
                <h3>{{ user.role }}</h3>
                <div class="edit">
                    <div class="icon">
                        <img src="@/assets/svg/options.svg" @click="user.options = !user.options" alt="">
                        <div class="options" :class="{ active: user.options }">
                            <div  @click="viewModal('edit',user)">
                                <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                Edit
                            </div>
                            <div  @click="$delete(user)">
                                <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <van-skeleton v-if="!isLoading" title :row="5" /> -->
    </div>
</template>
  
<script>
export default {
    name: 'Admin-Users',
    data() {
        return {
            isLoading: false,
            isDelete: false,
            viewmodal: false,
            editUserForm: null,
            modalType: null,
            userForm:{
                firstName: '',
                lastName: '',
                email: '',
                role: '',
                password: ''
            },
            deleteId: '',
            allstaff: []
        }
    },
    methods: {
        viewModal(type,user){
            let vm = this
            vm.modalType = type
            type == "edit" ? ((user.options = false), (vm.editUserForm = {...user})) : false
            vm.viewmodal = true
        },
        close(){
            let vm = this
            vm.viewadd = false
            vm.isDelete = false
            vm.deleteId = ''

            vm.viewmodal = false
            vm.modalType = null
            vm.editUserForm = null

            vm.userForm.firstName = ''
            vm.userForm.lastName = ''
            vm.userForm.email = ''
            vm.userForm.role = ''
            vm.userForm.password = ''
        },
        createUser(){
            let vm = this;
            vm.$store
                .dispatch('CREATE_USER', vm.userForm)
                .then((response) => {
                    vm.viewadd = false
                    vm.isLoading = false
                    vm.$notify({
                        title: 'Important Message',
                        text: 'Added Successfully'
                    })
                    vm.close();
                    vm.getAllUsers();
                })
                .catch(err => {
                    if(Array.isArray(err.response.data.message)){
                        err.response.data.message.forEach(alert =>{
                            vm.$notify({
                                type: 'error',
                                title: 'Important Message',
                                text: alert
                            })
                        })
                    }else{
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: err.response.data.message
                        })
                    }
                });
        },
        updateUser(){
            let vm = this;
            vm.$store
                .dispatch('UPDATE_USER', vm.editUserForm)
                .then((response) => {
                    vm.viewadd = false
                    vm.isLoading = false
                    vm.$notify({
                        title: 'Important Message',
                        text: 'Updated Successfully'
                    })
                    vm.close();
                    vm.getAllUsers();
                })
                .catch(err => {
                    if(Array.isArray(err.response.data.message)){
                        err.response.data.message.forEach(alert =>{
                            vm.$notify({
                                type: 'error',
                                title: 'Important Message',
                                text: alert
                            })
                        })
                    }else{
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: err.response.data.message
                        })
                    }
                });
        },
        confirmDelete(){
            let vm = this;
            vm.$store
            .dispatch("DELETE_USER", vm.deleteId)
            .then((response) => {
                vm.close()
                vm.getAllUsers()
                vm.$notify({
                    type: 'error',
                    title: 'Important Message',
                    text: response.data.message
                })
            })
        },
        getAllUsers(){
            let vm = this;
            vm.isLoading = true
            vm.$store
            .dispatch("GET_ALL_USERS")
            .then((response) => {
                vm.isLoading = false
                vm.allstaff = response.data.data
                vm.allstaff.forEach(user => {
                    user.options = false
                })
            })
            .catch(response => {
                vm.isLoading = false;  
            });
        }
    },
    mounted() {
        let vm = this
        vm.getAllUsers()
    }
}
</script>
<style scoped lang="scss">
.page-wrapper {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin-left: 260px;
    @media (max-width: 1070px) {
        margin: 0px;
    } 
    .empty{
        text-align: center;
        font-size: 0.85rem;
        color: #666666;
    }
    .van-skeleton{
        .van-skeleton__content{
            .van-skeleton-title,.van-skeleton-paragraph{
                background: #0e142ac0;
            }
        }
    }
    .header{
        padding: 10px 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        button {
            outline: none;
            border: 1px solid #0e142a60;
            border-radius: 7px;
            position: relative;
            overflow: hidden;
            border: none;
            font-size: 0.85rem;
            padding: 8px 25px;
            background: #0E142A;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .table{
        width: 100%;
        padding: 10px 30px;
        .headings,.details{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
        .headings{
            padding: 7px 4px;
            h2{
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 0.85rem;
            }
        }
        .details{
            margin: 10px 0;
            padding: 7px 4px;
            border-radius: 5px;
            &:nth-child(even){
                background: #0e142a7e;
                h3{
                    color: #fff;
                }
            }
            h3{
                padding: 0 0 0 5px;
                font-size: 0.85rem;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                &:first-child{
                    text-transform: capitalize;
                }
            }
            .edit{
                .icon{
                    margin: auto;
                    position: relative;
                    width: 20px;
                    height: 20px;
                    img{
                        width: 100%;
                        height: 100%;
                        top: 50%;
                        left: 50%; 
                        position: absolute;
                        transform: translate(-50%,-50%);
                        cursor: pointer;
                    }
                    .options{
                        display: none;
                        column-gap: 30px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0.8rem;
                        right: 100%;
                        z-index: 2;
                        padding: 5px 14px;
                        &.active{
                            display: flex;
                        }
                        div{
                            cursor: pointer;
                            display: flex;
                            column-gap: 7px;
                            border-radius: 5px;
                            padding: 3px 14px;
                            background: #ccc;
                        }
                    }
                }
            }
        }
    }
}
</style>
import { createApp } from 'vue'
import App from './App.vue'
const Vue = createApp(App)
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

// components
import sidebar from '@/components/sidebar'
import fixedbar from '@/components/fixedbar'
import navbar from '@/components/navbar'
import slider from '@/components/slider'
import categories from '@/components/categoriesSlider'
import search from '@/components/search'
import loader from '@/components/loader'
import modal from '@/components/Admin/modal'
import uploader from '@/components/Admin/uploader'
import SupportChat from "@/components/suportChat.vue"
import DropDown from "@/components/dropDown.vue"

// mixin
import apimixin from '@/mixins/mixin'
// plugin
import VueSocialSharing from 'vue-social-sharing'
import Notifications from '@kyvg/vue3-notification'
import VuePlyr from 'vue-plyr'
import mitt from 'mitt'

// vant ui
import { Popover, Button, Tab, TimePicker, Tabs, Progress, Loading, Skeleton, SkeletonParagraph, SkeletonAvatar, SkeletonTitle, Uploader, Icon } from 'vant';

import './assets/tailwind.css'
Vue.use(Button);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Skeleton);
Vue.use(Loading);
Vue.use(SkeletonParagraph);
Vue.use(SkeletonTitle);
Vue.use(SkeletonAvatar);
Vue.use(TimePicker);
Vue.use(Uploader);
Vue.use(Progress);
Vue.use(Icon);
Vue.use(Popover);

axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    /*
       When the server responds with UnAuthorized access error,
       take back the user to the login page
    */
    console.log(error)
    try {
        if (error.response.status === 403 || error.response.status === 401) {
            localStorage.removeItem("loggeduser");
            router.push({ path: '/admin/login' })
        }
    } catch (e) {
        console.log(e)
    }
    return Promise.reject(error);
});

Vue.use(store)
Vue.component('navbar', navbar)
Vue.use(VueSocialSharing);
Vue.use(VueAxios, axios)
Vue.use(VuePlyr, { plyr: {} })
Vue.use(Notifications)
Vue.mixin(apimixin)
Vue.component('search', search)
Vue.component('uploader', uploader)
Vue.component('Modal', modal)
Vue.component('loader', loader)
Vue.component('sidebar', sidebar)
Vue.component('fixedbar', fixedbar)
Vue.component('categories', categories)
Vue.component('slider', slider)
Vue.component('SupportChat', SupportChat)
Vue.component('DropDown', DropDown)
Vue.use(router)
const emitter = mitt()
Vue.config.globalProperties.emitter = emitter
Vue.mount('#app')
<template>
    <div class="page-wrapper flex">
        <div class="panel-left w-3/12 h-full border-r-2 border-gray-200 flex flex-col">
            <div class="flex h-12 place-items-center px-3  border-b-2 border-gray-200 bg-gray-200">
                <h3 class="text-lg">CONVERSATIONS</h3>
            </div>
            <div class="h-full overflow-auto">
                <ConversationItem v-for="(item, index) in 40" :key="index"></ConversationItem>
            </div>
        </div>
        <div class="panel-right w-9/12 h-full">
            <ChatHeader></ChatHeader>
            <div class="chats-body">
                <ChatMessageItem type="right"></ChatMessageItem>
            </div>
            <ChatMessageInput></ChatMessageInput>
        </div>
    </div>
</template>
  
<script>
import ConversationItem from "./components/ConversationItem.vue";
import ChatHeader from "./components/ChatHeader.vue";
import ChatMessageInput from "./components/ChatMessageInput.vue";
import ChatMessageItem from "./components/ChatMessageItem.vue"

export default {
    name: 'Admin-Support',
    components: {
        ConversationItem,
        ChatHeader,
        ChatMessageInput,
        ChatMessageItem
    },
    data() {
        return {

        }
    },
    computed: {

    },
    methods: {

    },
    mounted() {
        let vm = this

    }
}
</script>
<style scoped lang="scss">
.page-wrapper {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin-left: 250px;
    overflow-y: auto;

    .panel-right {
        .chats-body {
            height: calc(100% - 125px);
            overflow-y: auto;
            padding-top: 15px;
        }
    }

}
</style>
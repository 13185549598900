import { createRouter, createWebHistory } from 'vue-router'
import SiteWrapper from "@/siteWrapper.vue"
import Home from '../views/Home.vue'
import News from '../views/News.vue'
import NewsArticle from '../views/NewsArticle.vue'
import Program from '../views/Program.vue'
import Podcasts from '../views/Podcasts.vue'
import Podcast from '../views/Podcast.vue'
import Contactus from '../views/Contactus.vue'
import Business from '../views/Businessinrwanda'
import Visit from '../views/Visitrwanda'
import About from '../views/About'

// Admin
import AdminWrapper from "@/adminWrapper.vue"
import AdminLogin from '../views/Admin/Login.vue'
import AdminProgram from '../views/Admin/Program.vue'
import AdminPodcast from '../views/Admin/Podcast.vue'
import AdminNews from '../views/Admin/News.vue'
import AdminUsers from '../views/Admin/Users.vue'
import AdminSite from '../views/Admin/Site.vue'
import AdminSupport from '../views/Admin/SupportChat/index.vue'

import Search from '../components/search.vue'

const routes = [
  {
    path: '/',
    name: 'SiteWrapper',
    component: SiteWrapper,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: '/program',
        name: 'Program',
        component: Program
      },
      {
        path: '/podcasts',
        name: 'Podcasts',
        component: Podcasts
      },
      {
        path: '/podcasts/:id',
        name: 'Podcast',
        component: Podcast
      },
      {
        path: '/contactus',
        name: 'Contactus',
        component: Contactus
      },
      {
        path: '/news',
        name: 'News',
        component: News
      },
      {
        path: '/news/:id',
        name: 'NewsArticle',
        component: NewsArticle
      },
      {
        path: '/businessinrwanda',
        name: 'Business',
        component: Business
      },
      {
        path: '/visitrwanda',
        name: 'VisitRwanda',
        component: Visit
      },
      {
        path: '/aboutus',
        name: 'About',
        component: About
      },
      {
        path: '/search',
        name: 'Search',
        component: Search
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: AdminLogin
  },
  {
    path: '/admin',
    name: 'AdminWrapper',
    component: AdminWrapper,
    children: [
      {
        path: '',
        name: 'AdminProgram',
        component: AdminProgram
      },
      {
        path: 'podcast',
        name: 'AdminPodcast',
        component: AdminPodcast
      },
      {
        path: 'news',
        name: 'AdminNews',
        component: AdminNews
      },
      {
        path: 'support',
        name: 'Support',
        component: AdminSupport
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: AdminUsers
      },
      {
        path: 'site',
        name: 'AdminSite',
        component: AdminSite
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

<template>
    <div class="contain" :class="{ oncontain: openside }" @click.self="closeside">
        <div class="sidebar" :class="{ onside: openside }">
            <div class="sidebar-logo-widget">
                <router-link to="/" class="logo">
                    <img src="@/assets/logo.svg" alt="">
                </router-link>
                <div class="search-widget">
                    <div class="search">
                        <div class="input" :class="{ full: mobile }">
                            <input type="text" :class="{ full: mobile }" @focus="viewsearch()" @input="$searching(search)" v-model="search" placeholder="Search anything here">
                        </div>
                        <button @click="mobile = true">
                            <div class="icon">
                                <img src="@/assets/svg/search.svg" alt="">
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="menu-widget">
                <div class="main side-section">
                    <h2 class="heading">Main</h2>
                    <router-link to="/">
                        <div class="icon">
                            <img src="@/assets/svg/home.svg" alt="">
                        </div>
                        Home
                    </router-link>
                    <router-link to="/program">
                        <div class="icon">
                            <img src="@/assets/svg/live.svg" alt="">
                        </div>
                        Program
                    </router-link>
                    <router-link to="/podcasts"
                    :class="{ 'router-link-exact-active': podcast, 'router-link-active': podcast }">
                        <div class="icon">
                            <img src="@/assets/svg/podcast.svg" alt="">
                        </div>
                        Podcast
                    </router-link>
                    <router-link to="/contactus">
                        <div class="icon">
                            <img src="@/assets/svg/phone.svg" alt="">
                        </div>
                        Contact us
                    </router-link>
                    <router-link to="/news"
                        :class="{ 'router-link-exact-active': article, 'router-link-active': article }">
                        <div class="icon">
                            <img src="@/assets/svg/news.svg" alt="">
                        </div>
                        News
                    </router-link>
                </div>
                <div class="other side-section">
                    <h2 class="heading">Other</h2>
                    <router-link to="/visitrwanda">
                        <div class="icon">
                            <img src="@/assets/svg/globe.svg" alt="">
                        </div>
                        Visit Rwanda
                    </router-link>
                    <router-link to="/businessinrwanda">
                        <div class="icon">
                            <img src="@/assets/svg/business.svg" alt="">
                        </div>
                        Business in Rwanda
                    </router-link>
                    <router-link to="/aboutus">
                        <div class="icon">
                            <img src="@/assets/svg/info.svg" alt="">
                        </div>
                        About us
                    </router-link>
                </div>
                <div class="buttons-links">
                    <button @click="onOpenSupportChart()">
                        <div class="icon">
                            <img src="@/assets/svg/ellipse.svg" alt="">
                        </div>
                        Support chat
                    </button>
                    <a href="https://play.google.com/store/apps" target="_blank">
                        <img src="@/assets/svg/playstore.svg" alt="">
                    </a>
                </div>
            </div>

            <div class="social-links">
                <a href="https://www.tiktok.com" target="_blank" class="social">
                    <div class="icon">
                        <img src="@/assets/svg/tiktok.svg" width="27">
                    </div>
                </a>
                <a href="https://www.linkedin.com/" target="_blank" class="social">
                    <div class="icon">
                        <img src="@/assets/svg/linkedin.svg" width="24">
                    </div>
                </a>
                <a href="https://www.youtube.com" target="_blank" class="social">
                    <div class="icon">
                        <img src="@/assets/svg/youtube.svg" width="30">
                    </div>
                </a>
                <a href="https://www.facebook.com/Cityradio88.3fm/" target="_blank" class="social">
                    <div class="icon">
                        <img src="@/assets/svg/lfacebook.svg" width="26">
                    </div>
                </a>
                <a href="https://www.instagram.com/city_radio_88.3_fm/" target="_blank" class="social">
                    <div class="icon">
                        <img src="@/assets/svg/instagram.svg" width="24">
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sidebar',
    data() {
        return {
            currentUrl: window.location.href,
            search: '',
            mobile: false
        }
    },
    computed: {
        openside() {
            return this.$store.state.openSidebar;
        },
        article() {
            return this.currentUrl.includes('news')
        },
        podcast() {
            return this.currentUrl.includes('podcast')
        },
    },
    watch:{
        '$route' (value){
            this.checkPage()
        }
    },
    methods: {
        onOpenSupportChart() {
            let vm = this;
            vm.$emit('onOpenSupportChart');
        },
        checkPage(){
            this.currentUrl = window.location.href
        },
        closeside() {
            this.$store.state.openSidebar = false
        },
        viewsearch(){
            this.$router.push('/search')
        }
    },
}    
</script>
<style lang="scss">
.contain {
    width: 22%;
    height: 100%;

    @media (max-width: 1070px) {
        background: #00000063;
        width: 100%;
        height: 100%;
        z-index: 999999;
        position: fixed;
        left: 0;
        opacity: 0;
        transition: .6s;
        pointer-events: none;

        &.oncontain {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 260px;
    background: #dedede;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    border-right: 2px solid #5c689e50;
    overflow-y: auto;

    @media (max-width: 1070px) {
        transition-delay: 1s;
        transition: .6s;
        width: 260px;
        opacity: 0;
        transform: translateX(-50px);

        &.onside {
            transform: translateX(0px);
            opacity: 1;
            pointer-events: all;
        }
    }

    .sidebar-logo-widget {
        height: 180px;
        padding-top: 20px;

        .logo {
            flex-shrink: 0;
            margin-left: 50px;
            margin-bottom: 30px;
            img {
                width: 140px;
            }
        }

        .search-widget {
            padding: 0px 10px;

            @media (max-width: 1070px) {
                display: none;
            }

            .search {
                margin-top: 20px;
                display: flex;
                align-content: center;
                font-size: 0.9rem;
                overflow: hidden;
                position: relative;
                height: 40px;
                width: 100%;
                overflow: hidden;
                border-radius: 4px;
                .input {
                    transition: .6s;
                    width: 100%;
                    display: flex;

                    input {
                        padding: 0px 20px;
                        height: 40px;
                        color: #0E142A;
                        font-size: 0.8rem;
                        width: 100%;
                        border: none;

                        &::placeholder {
                            font-size: 0.8rem;
                            color: #878787;

                            @media (max-width: 500px) {
                                font-size: 0.75rem;
                            }
                        }
                    }
                }

                button {
                    width: 45px;
                    height: 40px;
                    background: #E0291B;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;

                    .icon {
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    .menu-widget {
        height: calc(100% - 271px);
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 4px;
            @media (max-width: 480px) {
            width: 3px;
            }
        }
        &::-webkit-scrollbar-thumb {
            background: #0E142A;
        }
        .side-section {
            padding: 0 30px;
            width: 100%;
            height: fit-content;

            .heading {
                // border: 1px solid salmon;
                color: #0E142A;
                text-transform: uppercase;
                font-size: 0.9rem;
                font-weight: 500;
            }

            a {
                // border: 1px solid salmon;
                display: flex;
                align-items: center;
                width: 100%;
                margin: 15px auto;
                font-size: 0.8rem;
                text-decoration: none;
                color: #0E142A;
                transition: .6s;
                text-transform: uppercase;

                &.router-link-exact-active {
                    color: #E0291B;

                    .icon {
                        background: #E0291B;
                    }
                }

                &:hover {
                    color: #E0291B;

                    .icon {
                        background: #E0291B;
                    }
                }

                .icon {
                    transition: .6s;
                    margin: 0 20px 0 0;
                    position: relative;
                    width: 25px;
                    height: 25px;
                    flex-shrink: 0;
                    cursor: pointer;
                    border-radius: 100%;
                    background: #0E142A;

                    img {
                        width: 50%;
                        height: 50%;
                        top: 50%;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        .buttons-links {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0px 35px;
            row-gap: 10px;
            margin-top: 60px;

            button {
                all: unset;
                display: flex;
                align-items: center;
                cursor: pointer;
                font-size: 0.8rem;
                text-transform: uppercase;
                color: #fff;
                background: #0E142A;
                padding: 3px 15px;
                border-radius: 5px;

                .icon {
                    margin: 0 10px 0 0;
                    position: relative;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;

                    img {
                        width: 50%;
                        height: 50%;
                        top: 50%;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            a {
                width: 120px;
                height: 40px;
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: fit;
                }
            }
        }
    }

    .social-links {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0px 25px;

        a {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
<template>
    <div class="page-wrapper">
        <Modal v-if="isDelete"  @click.self="close()">
            <div class="delete">
                <h2>Are you sure you want to Delete this Program?</h2>
                <div class="buttons">
                    <button class="cancel" @click="close()">Cancel</button>
                    <button class="del" @click="confirmDelete()">Delete</button>
                </div>
            </div>
        </Modal>
        <Modal v-if="viewmodal" @click.self="close()">
            <form @submit.prevent="createProgram" v-if="modalType == 'add'">
                <div class="name-close">
                    <h1>Add Program</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Title</label>
                    <input type="text" class="input" v-model="programForm.title" required>
                    <label>Day</label>
                    <select v-model="programForm.day" required>
                        <option value="" selected hidden>Select Day</option>
                        <option :value="day.name" v-for="(day,index) in days" :key="index">{{ day.name }}</option>
                    </select>
                    <label>StartTime</label>
                    <VueDatePicker required v-model="programForm.startTime" time-picker></VueDatePicker>
                    <label>End Time</label>
                    <VueDatePicker required v-model="programForm.endTime" time-picker></VueDatePicker>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Add</button>
                </div>
            </form>
            <form @submit.prevent="updateProgram" v-else>
                <div class="name-close">
                    <h1>Add Update</h1>
                    <div class="icon" @click="close">
                        <img src="@/assets/svg/close.svg" alt="">
                    </div>
                </div>
                <div class="inputs">
                    <label>Title</label>
                    <input type="text" class="input" v-model="editProgramForm.title" required>
                    <label>Day</label>
                    <select v-model="editProgramForm.day" required>
                        <option value="" selected hidden>Select Day</option>
                        <option :value="day.name" v-for="(day,index) in days" :key="index">{{ day.name }}</option>
                    </select>
                    <label>StartTime</label>
                    <VueDatePicker required v-model="editProgramForm.startTime" time-picker></VueDatePicker>
                    <label>End Time</label>
                    <VueDatePicker required v-model="editProgramForm.endTime" time-picker></VueDatePicker>
                </div>
                <div class="buttons">
                    <button class="cancel" @click="close">Cancel</button>
                    <button class="save">Update</button>
                </div>
            </form>
        </Modal>
        <div class="header">
            <button @click="viewModal('add')">Add Program</button>
        </div>
        <div class="days">
            <button :class="{ active: day.isActive }" v-for="(day,index) in days" :key="index" @click="viewday(day.name)">{{ day.name }}</button>
        </div>
        <div v-if="!dayPrograms.length" class="empty">No Programs available!</div>
        <div v-else class="table">
            <div class="headings">
                <h2>Program Title</h2>
                <h2>Program Time</h2>
                <h2></h2>
            </div>
            <div class="details" v-for="program in dayPrograms" :key="program.id">
                <h3>{{ program.title }}</h3>
                <h3>{{ hm(program.startTime) }} - {{ hm(program.endTime) }}</h3>
                <div class="edit">
                    <div class="icon">
                        <img src="@/assets/svg/options.svg" @click="program.options = !program.options" alt="">
                        <div class="options" :class="{ active: program.options }">
                            <div  @click="viewModal('edit',program)">
                                <div class="icon"><img src="@/assets/svg/edit.svg" alt=""></div>
                                Edit
                            </div>
                            <div  @click="$delete(program)">
                                <div class="icon"><img src="@/assets/svg/delete.svg" alt=""></div>
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
export default {
    name: 'Admin-Program',
    components: { VueDatePicker },
    data() {
        return {
            isDelete: false,
            viewmodal: false,
            editProgramForm: null,
            modalType: null,
            deleteId: '',
            
            programForm:{
                title: '',
                day: '',
                startTime: '',
                endTime: '',
            },
            selectedDay: 'Monday',
            days: [
                {
                    name: "Monday",
                    isActive: true,
                },
                {
                    name: "Tuesday",
                    isActive: false,
                },
                {
                    name: "Wednesday",
                    isActive: false,
                },
                {
                    name: "Thursday",
                    isActive: false,
                },
                {
                    name: "Friday",
                    isActive: false,
                },
                {
                    name: "Saturday",
                    isActive: false,
                },
                {
                    name: "Sunday",
                    isActive: false,
                },
            ],
            allprograms: []
        }
    },
    computed:{
        dayPrograms(){
            let vm = this
            return vm.allprograms.filter(program => program.day == vm.selectedDay)
        }
    },
    methods: {
        viewday(day){
            let vm = this
            vm.selectedDay = day
            vm.days.forEach(anyday =>{
                if(day == anyday.name){
                    anyday.isActive = true
                }else{
                    anyday.isActive = false
                }
            })
        },
        viewModal(type,program){
            let vm = this
            vm.modalType = type
            if (type === "edit") { 
                program.options = false;
                vm.editProgramForm = {...program};
                vm.editProgramForm.startTime = { hours: vm.editProgramForm.startTime.split(':')[0], minutes: vm.editProgramForm.startTime.split(':')[1], seconds: "00"};
                vm.editProgramForm.endTime = { hours: vm.editProgramForm.endTime.split(':')[0], minutes: vm.editProgramForm.endTime.split(':')[1], seconds: "00"};
            }
            vm.viewmodal = true
        },
        close(){
            let vm = this
            vm.viewadd = false
            vm.isDelete = false
            vm.deleteId = ''

            vm.viewmodal = false
            vm.modalType = null
            vm.editProgramForm = null

            vm.programForm.title = ''
            vm.programForm.day = ''
            vm.programForm.startTime = ''
            vm.programForm.endTime = ''
        },
        hm(time){
            return time.split(':')[0] + ":" + time.split(':')[1]
        },
        getAllPrograms(){
            let vm = this;
            vm.isLoading = true
            vm.$store
            .dispatch("GET_ALL_PROGRAMS")
            .then((response) => {
                vm.isLoading = false
                vm.allprograms = response.data.data
                vm.allprograms.forEach(program => {
                    program.options = false
                })
            })
            .catch(response => {
                vm.isLoading = false;  
            });
        },
        createProgram(){
            let vm = this
            vm.programForm.startTime = vm.programForm.startTime.hours + ':' + vm.programForm.startTime.minutes
            vm.programForm.endTime = vm.programForm.endTime.hours + ':' + vm.programForm.endTime.minutes
            vm.$store
            .dispatch('CREATE_PROGRAM', vm.programForm)
            .then((response) => {
                vm.$notify({
                    title: 'Important Message',
                    text: "Program created successfully"
                })
                vm.getAllPrograms()
                vm.close()
            })
            .catch(err => {
                if(Array.isArray(err.response.data.message)){
                    err.response.data.message.forEach(alert =>{
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: alert
                        })
                    })
                }else{
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: err.response.data.message
                    })
                }
            });
        },
        updateProgram(){
            let vm = this;
            vm.editProgramForm.startTime = vm.editProgramForm.startTime.hours + ':' + vm.editProgramForm.startTime.minutes
            vm.editProgramForm.endTime = vm.editProgramForm.endTime.hours + ':' + vm.editProgramForm.endTime.minutes
            vm.$store
            .dispatch('UPDATE_PROGRAM', vm.editProgramForm)
            .then((response) => {
                vm.viewadd = false
                vm.isLoading = false
                vm.$notify({
                    title: 'Important Message',
                    text: 'Updated Successfully'
                })
                vm.close()
                vm.getAllPrograms()
            })
            .catch(err => {
                if(Array.isArray(err.response.data.message)){
                    err.response.data.message.forEach(alert =>{
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: alert
                        })
                    })
                }else{
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: err.response.data.message
                    })
                }
            });
        },
        confirmDelete(){
            let vm = this;
            vm.$store
            .dispatch("DELETE_PROGRAM", vm.deleteId)
            .then((response) => {
                vm.close()
                vm.getAllPrograms()
                vm.$notify({
                    type: 'error',
                    title: 'Important Message',
                    text: response.data.message
                })
            })
        },
    },
    mounted() {
        let vm = this
        vm.getAllPrograms()
    }
}
</script>
<style scoped lang="scss">
.page-wrapper {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    margin-left: 260px;
    @media (max-width: 1070px) {
        margin: 0px;
    }
    .header{
        padding: 10px 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        button {
            outline: none;
            border: 1px solid #0e142a60;
            border-radius: 7px;
            position: relative;
            overflow: hidden;
            border: none;
            font-size: 0.85rem;
            padding: 8px 25px;
            background: #0E142A;
            color: #fff;
            font-weight: 600;
            cursor: pointer;
        }
    }
    .empty{
        margin: 30px 0;
        text-align: center;
        font-size: 0.85rem;
        color: #666666;
    }
    .days{
        display: flex;
        justify-content: space-around;
        margin: 10px 0;
        button {
            outline: none;
            border: 1px solid #0e142a13;
            border-radius: 7px;
            position: relative;
            overflow: hidden;
            font-size: 0.85rem;
            padding: 5px 25px;
            color: #0E142A;
            font-weight: 600;
            cursor: pointer;
            &.active{
                background: #0E142A;
                color: #fff;
            }
        }
    }
    .table{
        width: 100%;
        padding: 10px 30px;
        .headings,.details{
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }
        .headings{
            padding: 7px 4px;
            h2{
                font-size: 0.85rem;
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .details{
            margin: 10px 0;
            padding: 7px 4px;
            border-radius: 5px;
            &:nth-child(even){
                background: #0e142a7e;
                h3{
                    color: #fff;
                }
            }
            h3{
                padding: 0 0 0 5px;
                font-size: 0.85rem;
                text-transform: capitalize;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .edit{
                .icon{
                    margin: auto;
                    position: relative;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        top: 50%;
                        left: 50%; 
                        position: absolute;
                        transform: translate(-50%,-50%);
                    }
                    .options{
                        display: none;
                        column-gap: 30px;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 0.8rem;
                        right: 100%;
                        z-index: 2;
                        padding: 5px 14px;
                        &.active{
                            display: flex;
                        }
                        div{
                            cursor: pointer;
                            display: flex;
                            column-gap: 7px;
                            border-radius: 5px;
                            padding: 3px 14px;
                            background: #ccc;
                        }
                    }
                }
            }
        }
    }
}
</style>
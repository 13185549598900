<template>
    <div class="navbar">
        <div class="menuLogo">
            <div class="menu" @click="activateSide">
                <div class="icon">
                    <img src="@/assets/svg/menu.svg">
                </div>
            </div>
        </div>
        <router-link to="/" class="reallogo">
            <img src="@/assets/logo.svg" alt="">
        </router-link>
        <div class="search">
            <div class="input" :class="{ full: mobile }">
                <input type="text" :class="{ full: mobile }" @input="$searching(search)" @focus="viewsearch()" placeholder="Search anything here" v-model="search" required>
                <div class="icon" @click="mobile = false">
                    <img src="@/assets/svg/wclose.svg" alt="">
                </div>
            </div>
            <button @click="mobile = true">
                <div class="icon">
                    <img src="@/assets/svg/search.svg" alt="">
                </div>
            </button>
        </div>

    </div>
</template>
<script>
export default {
    name: 'navbar',
    data() {
        return {
            search: '',
            mobile: false
        }
    },
    methods: {
        activateSide() {
            this.$store.state.openSidebar = true
        },
        viewsearch(){
            this.$router.push('/search')
        }
    }
}
</script>
<style lang="scss">
.navbar {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    height: 70px;
    position: fixed;
    top: 0;
    left: 250px;
    right: 0;
    z-index: 999;
    background: #e2e2e2;
    border-bottom: 1px solid #bababa50;

    @media (max-width: 1070px) {
        display: flex;
        left: 0;
    }

    @media (max-width: 450px) {
        display: flex;
        padding: 0px 25px;
    }

    .menuLogo {
        display: none;
        align-items: center;

        @media (max-width: 1070px) {
            display: flex;
        }

        .menu {
            .icon {
                margin: auto;
                position: relative;
                width: 25px;
                height: 25px;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .reallogo {
        display: none;

        @media (max-width: 1070px) {
            display: flex;
            flex-shrink: 0;

            img {
                width: 80px;
            }
        }
    }

    .search {
        display: flex;
        align-content: center;
        font-size: 0.9rem;
        overflow: hidden;
        border-radius: 6px;
        position: relative;
        height: fit-content;
        background: #0E142A;

        @media (max-width: 1070px) {
            position: static;
            background: transparent;
        }

        input,
        button {
            all: unset;
        }

        .input {
            transition: .6s;

            .icon {
                display: none;
            }

            &.full {
                .icon {
                    cursor: pointer;
                    z-index: 99;
                    display: flex;
                    width: 40px;
                    height: 40px;
                    position: relative;


                    @media (max-width: 400px) {
                        left: 25px;
                    }

                    img {
                        width: 50%;
                        height: 50%;
                        top: 50%;
                        left: 50%;
                        position: absolute;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }

        input {
            padding: 0px 20px;
            height: 40px;
            color: #ffffff;
            font-size: 0.8rem;
            width: 250px;

            @media (max-width: 1070px) {
                trasition: .6s;
                padding: 0;
                overflow: hidden;
                width: 0;
                opacity: 0;

                &.full {
                    opacity: 1;
                    background: #0E142A;
                    position: absolute;
                    padding: 0 50px;
                    z-index: 99;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;

                    @media (max-width: 400px) {
                        padding: 0 25px;
                    }
                }
            }

            &::placeholder {
                font-size: 0.8rem;
                color: #878787;

                @media (max-width: 500px) {
                    font-size: 0.75rem;
                }
            }
        }

        button {
            width: 40px;
            height: 40px;
            background: #E0291B;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: 1070px) {
                border-radius: 100%;
                overflow: hidden;
            }

            .icon {
                width: 30px;
                height: 30px;
                position: relative;

                img {
                    width: 50%;
                    height: 50%;
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }


}
</style>
<template>
    <div class="page-content">
        <loader v-if="isLoading" />
        <div class="aboutus-address">
            <h3 class="header">About City Radio</h3>
            <div class="aboutus">
                <p class="welcome">Welcome to Radio, the ultimate destination for music lovers and audiophiles! With a
                    wide range of music genres, the latest hits and timeless classics, Radio has something for everyone.
                </p>
                <p class="mission">Our mission is to bring you the best listening experience possible, with crystal
                    clear sound quality, expertly curated playlists, and a user-friendly interface.</p>

                <p class="other">Whether you're at home, on the go, or in the office, Radio is your companion for music
                    and entertainment. So sit back, relax, and enjoy the sounds of Radio.</p>
                <label>Tune in now and experience the difference!</label>
            </div>
            <div class="address">
                <h4>Ready to take your business to next level?</h4>
                <p>Contact us to learn how we can help you grow, innovate and expand your business</p>
                <h4>CITY RADIO LTD</h4>
                <label class="currentaddress">Rwanda - Kigali - Dorona House 3RD Floor KN 84 st N</label>
                <a href="tel:0788304596">Phone: 0788304596</a>
                <a href="mailto:info@cityradio.rw">Email: info@cityradio.rw</a>
            </div>
        </div>
        <fixedbar />
    </div>
</template>
  
<script>
export default {
    name: 'About',
    data() {
        return {
            myTab: 'recentNews',
            isLoading: false,
            viewsearch: false,
            searched: '',
            news: [],
            podcasts: [],
            jobs: [],
        }
    },
    methods: {
        searchthis(searched) {
            this.viewsearch = true
            this.searched = searched
        }
    },
    mounted() {
        let vm = this
        vm.news = vm.$getAllNews()
        vm.podcasts = vm.$getAllPodcasts()
        vm.jobs = vm.$getAllJobs()
        vm.$store.state.openSidebar = false
    }
}
</script>
<style scoped lang="scss">
.page-content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 0 100px 0;

    &::-webkit-scrollbar {
        width: 10px;

        @media (max-width: 480px) {
            width: 5px;
        }
    }

    &::-webkit-scrollbar-thumb {
        width: 20px;
        background: #0E142A;
    }

    .search-enter-active,
    .search-leave-active {
        transition: .5s;
    }

    .search-enter-from,
    .search-leave-to {
        transform: translateY(20px);
        opacity: 0;
    }

    .aboutus-address {
        padding: 10px 40px;
        font-size: 0.8rem;

        @media (max-width: 480px) {
            padding: 10px 20px;
        }

        h3 {
            font-size: 1.2rem;
        }

        .header {
            padding: 10px 0px;
            border-bottom: 3px solid #E0291B;

            h2 {
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .aboutus {
            width: 80%;
            padding: 20px 0;

            @media (max-width: 500px) {
                width: 100%;
            }

            .welcome,
            .mission,
            .other {
                font-size: 0.8rem;
                margin: 0 0 15px;
            }

            label {
                font-weight: 600;
            }
        }

        .address {
            padding: 10px 0 0;

            p {
                margin: 10px 0;
            }

            a {
                margin: 10px 0;
                font-size: 0.8rem;
                color: #0E142A;
                display: block;

                &.hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
<template>
    <div class="content">
        <loader v-if="isLoading" />
        <div class="allpodcasts">
            <div class="header">
                <h2>PODCAST</h2>
            </div>
            <div class="allpodcasts-timeline">
                <h4>TODAY</h4>
                <div>
                    <div class="podcasts">
                        <router-link class="podcast" :to="{ path: '/podcasts/' + podcast.id }" v-for="podcast in allpodcasts.today" :key="podcast.id">
                            <div class="article-img">
                                <img :src="assetsUrl + podcast.imageFileName" alt="">
                            </div>
                            <div class="description">
                                <h2 class="heading">{{ podcast.title }}</h2>
                                <p class="newsarticle">{{ podcast.content }}</p>
                            </div>
                        </router-link>
                    </div>
                </div>
                <h4>YESTERDAY</h4>
                <div class="podcasts">
                    <router-link class="podcast" :to="{ path: '/podcasts/' + podcast.id }" v-for="podcast in allpodcasts.yesterday" :key="podcast.id">
                        <div class="article-img">
                            <img :src="assetsUrl + podcast.imageFileName" alt="">
                        </div>
                        <div class="description">
                            <h2 class="heading">{{ podcast.title }}</h2>
                            <p class="newsarticle">{{ podcast.content }}</p>
                        </div>
                    </router-link>
                </div>
                <h4>OLDER</h4>
                <div class="podcasts">
                    <router-link class="podcast" :to="{ path: '/podcasts/' + podcast.id }" v-for="podcast in allpodcasts.older" :key="podcast.id">
                        <div class="article-img">
                            <img :src="assetsUrl + podcast.imageFileName" alt="">
                        </div>
                        <div class="description">
                            <h2 class="heading">{{ podcast.title }}</h2>
                            <p class="newsarticle">{{ podcast.content }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>
    
<script>
import { ASSETS_BASE_URL } from '@/store/variables'

export default {
    name: 'Podcast',
    data() {
        return {
            assetsUrl: ASSETS_BASE_URL,
            isLoading: false,
            viewsearch: false,
            searched: '',
            allpodcasts: [],
            alljobs: [],
        }
    },
    computed:{
        hasTodayPodcasts(){
            return this.allpodcasts.today > 0
        },
        hasYesterdayPodcasts(){
            return this.allpodcasts.yesterday > 0
        },
        hasOldercasts(){
            return this.allpodcasts.older > 0
        },
    },
    methods: {
        playPod(audio, details) {
            if (audio[0].paused) {
                document.querySelectorAll('audio').forEach(aud => aud.pause())
                audio[0].play()
                details.isPlaying = true
            } else {
                document.querySelectorAll('audio').forEach(aud => aud.pause())
                audio[0].pause()
                details.isPlaying = false
            }
        },
    },
    mounted() {
        let vm = this
        vm.$store.state.openSidebar = false
        vm.$getAllTimelinePodcasts()
    }
}
</script>
<style scoped lang="scss">
.content {
    width: 100%;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    padding: 15px 30px 50px 30px;

    @media (max-width: 450px) {
        padding: 10px 10px 150px 10px;
    }

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        width: 20px;
        background: #0E142A;
    }

    .search-enter-active,
    .search-leave-active {
        transition: .5s;
    }

    .search-enter-from,
    .search-leave-to {
        transform: translateY(20px);
        opacity: 0;
    }

    .allpodcasts {
        padding: 10px 0px;
        .header {
            padding: 10px 0px;
            border-bottom: 3px solid #E0291B;
            padding-bottom: 20px;
            h2 {
                font-size: 1rem;
                font-weight: 600;
            }
        }
        h4{
            font-size: 1rem;
            font-weight: 600;
            margin: 10px 0 0;
        }
        .podcasts {
            display: grid;
            column-gap: 20px;
            row-gap: 40px;
            margin: 30px 0;
            grid-template-columns: 1fr 1fr 1fr 1fr; 
            @media (max-width: 840px) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @media (max-width: 550px) {
                grid-template-columns: 1fr 1fr;
            }
            a {
                text-decoration: none;
                color: #0E142A;

                .article-img {
                width: 100%;
                height: 200px;
                position: relative;
                overflow: hidden;
                border-radius: 6px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
                }

                .description {
                padding: 10px 0px;
                width: 100%;

                .heading {
                    overflow: hidden;
                    font-size: 0.95rem;
                    box-orient: vertical;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    line-clamp: 1;
                    -webkit-line-clamp: 1;
                    text-overflow: ellipsis;
                    @media (max-width: 840px) {
                        line-clamp: 2;
                        -webkit-line-clamp: 2;
                    }
                }
                .newsarticle {
                    font-size: 0.8rem;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;

                    @media (max-width: 840px) {
                    line-clamp: 3;
                    -webkit-line-clamp: 3;
                    }

                    @media (max-width: 768px) {
                    max-height: 65px;
                    }
                }
                }
            }
        }
    }
}
</style>
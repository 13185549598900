<template>
  <div class="content">
    <loader v-if="isLoading" />
    <div class="allnews">
      <h2 class="heading">News</h2>
      <div class="wrapper" v-if="article">
        <div class="newsArticle">
          <div class="top">
            <div class="articleImg">
              <img :src="assetsUrl + article.imageFileName" alt="">
            </div>
          </div>
          <div class="openArticle">
            <h3>{{ article.title }}</h3>
            <label class="time">{{ datetime(article.createdAt) }} - <span>Written by {{ article.author }}</span></label>
            <div class="share">
              <h4>SHARE:</h4>
              <div class="socialmedias">
                <ShareNetwork network="facebook" :url="articleLink + article.id" :title="article.title">
                  <div class="icon">
                    <img src="@/assets/svg/lfacebook.svg" alt="">
                  </div>
                </ShareNetwork>
                <ShareNetwork network="twitter" :url="articleLink + article.id" :title="article.title">
                  <div class="icon">
                    <img src="@/assets/svg/ltwitter.svg" alt="">
                  </div>
                </ShareNetwork>
                <ShareNetwork network="whatsapp" :url="articleLink + article.id" :title="article.title">
                  <div class="icon">
                    <img src="@/assets/svg/whatsapp.svg" alt="">
                  </div>
                </ShareNetwork>
              </div>
            </div>
            <div class="breaking" v-if="article.summary">
              <h3>INCAMAKE</h3>
              <p>{{ article.summary }}</p>
            </div>
            <div v-html="article.content" class="body"></div>
          </div>
          <div class="commentSection">
            <h4>COMMENTS</h4>
            <form @submit.prevent="newComment()">
              <textarea required v-model="comment" placeholder="Write Something..." rows="5"></textarea>
              <button type="submit">POST COMMENT</button>
            </form>
            <div class="recentComments">
              <div class="comments" v-if="allcomments.length">
                <div class="comment" v-for="comment in allcomments" :key="comment.id">
                  <button class="user">
                    <img src="@/assets/svg/user.svg" alt="">
                  </button>
                  <div class="description">
                    <!-- <div class="flex">
                      <h4 class="name">unkwown</h4>
                    </div> -->
                    <div class="newsarticle">{{ comment.message }}</div>
                    <p class="time">{{ datetime(comment.createdAt) }}</p>
                  </div>
                </div>
              </div>
              <div class="empty" v-else>No comments yet!</div>
            </div>
          </div>
        </div>
        <div class="recentnews">
          <h3>RECENT NEWS</h3>
          <div class="news">
            <router-link :to="{ path: '/news/' + article.id }" v-for="article in recentarticles.slice(0, 4)"
              :key="article.id">
              <div class="article-img">
                <img :src="assetsUrl + article.imageFileName" alt="">
              </div>
              <div class="description">
                <h2 class="heading">{{ article.title }}</h2>
                <p class="time">{{ datetime(article.createdAt) }}</p>
                <p class="newsarticle" v-html="article.content"></p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="error404" v-else>
        <router-link to="/news">GO TO NEWS</router-link>
        <img src="@/assets/svg/article.svg" draggable="false" alt="">
        <h4>Oops! Article not found</h4>
      </div>
    </div>
  </div>
</template>
  
<script>
import { ASSETS_BASE_URL } from '@/store/variables'
export default {
  name: 'Article',
  data() {
    return {
      assetsUrl: ASSETS_BASE_URL,
      articleLink: 'https://cityradio.rw/news/',
      isLoading: false,
      comment: '',
      viewsearch: false,
      searched: '',
      allnews: [],
      podcasts: [],
      jobs: [],
      allcomments: [],
    }
  },
  computed: {
    article() {
      let check = this.allnews.some(element => this.$route.params.id == element.id)
      if (check == true) {
        this.getAllComments()
        return this.allnews.find(element => this.$route.params.id == element.id)
      } else {
        return false
      }
    },
    recentarticles() {
      return this.allnews.filter(element => this.$route.params.id != element.id)
    },
  },
  methods: {
    newComment() {
      console.log(this.comment)
    },
    datetime(time) {
      return time.split('T')[1].split(':')[0] + ":" + time.split('T')[1].split(':')[1] + ", " + time.split('T')[0]
    },
    searchthis(searched) {
      this.viewsearch = true
      this.searched = searched
    },
    newComment() {
      let vm = this;
      vm.submitloading = true;
      vm.$store
        .dispatch("CREATE_COMMENT", { message: vm.comment, newsId: vm.article.id })
        .then((response) => {
          vm.comment = ''
          vm.$notify({
            title: 'Important Message',
            text: 'Comment Submitted'
          })
          vm.getAllComments()
        })
        .catch((err) => {
          console.log(err)
          vm.$notify({
            type: 'error',
            title: 'Important Message',
            text: err.data.message
          })
        });
    },
    getAllComments() {
      let vm = this;
      vm.submitloading = true;
      vm.$store
        .dispatch("GET_ALL_COMMENTS", { id: vm.$route.params.id })
        .then((response) => {
          vm.allcomments = response.data.data
        })
        .catch((err) => {
          console.log(err)
        });
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    let vm = this
    vm.$getAllNews()
    vm.getAllComments()
  }
}
</script>
<style scoped lang="scss">
.content {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  padding: 40px 40px 80px 40px;

  @media (max-width: 1070px) {
    padding: 20px 20px 100px 20px;
  }

  &::-webkit-scrollbar {
    width: 10px;

    @media (max-width: 480px) {
      width: 4px;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 20px;
    background: #0E142A;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 70% 30%;
    column-gap: 10px;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  .newsArticle {
    margin: 20px 0;

    .top {
      column-gap: 30px;
      margin: 0 0 40px 0;

      @media (max-width: 1200px) {

        .articleImg {
          width: 100%;
          height: 400px;
        }
      }

      @media (max-width: 850px) {
        .articleImg {
          width: 100%;
          height: 400px;
        }
      }

      @media (max-width: 630px) {
        .articleImg {
          height: 300px;
        }
      }
    }

    .articleImg {
      width: 600px;
      height: 300px;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      flex-shrink: 0;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .openArticle {
      padding: 10px 0;

      h3 {
        font-size: 1.2rem;

        @media (max-width: 630px) {
          font-size: 1rem;
        }
      }

      .time {
        font-size: 0.8rem;
        color: #646464;
        margin: 10px 0 20px 0;
        display: block;
      }

      .share {
        display: flex;
        column-gap: 20px;
        align-items: center;

        .socialmedias {
          display: flex;
          align-items: center;
          column-gap: 20px;

          .icon {
            margin: auto;
            position: relative;
            width: 25px;
            height: 25px;
            cursor: pointer;

            &:not(:last-child) {
              margin-left: 10px;
            }

            img {
              width: 100%;
              height: 100%;
              top: 50%;
              left: 50%;
              position: absolute;
              transform: translate(-50%, -50%);
            }
          }
        }
      }

      .breaking {
        margin-top: 20px;

        h3 {
          font-size: 1rem;
        }

        p {
          font-size: 0.8rem;
          margin: 20px 0 0 0;
          line-height: 1.6rem;
          color: #0E142A;
        }
      }

      .body {
        font-size: 0.85rem;
        margin: 20px 0 0 0;
        line-height: 2rem;
        color: #0E142A;

        @media (max-width: 630px) {
          font-size: 0.8rem;
        }
      }
    }

    .commentSection {
      width: 60%;

      @media (max-width: 768px) {
        width: 100%;
      }

      h4 {
        margin: 0 0 10px 0;
      }

      textarea {
        all: unset;
        display: flex;
        resize: none;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 0.8rem;
        background: #EFEFEF;
      }

      button {
        all: unset;
        border-radius: 6px;
        background: #0E142A;
        color: #fff;
        font-size: 0.8rem;
        padding: 5px 20px;
        cursor: pointer;
        margin: 20px 0;
      }

      .recentComments {
        width: 100%;
        margin: 20px 0 0 0;

        .empty {
          width: 100%;
          font-size: 0.8rem;
          color: #646464;
        }

        .comments {
          column-gap: 20px;
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          .comment {
            display: flex;
            column-gap: 20px;
            width: 100%;

            .user {
              all: unset;
              position: relative;
              width: 30px;
              height: 30px;
              cursor: pointer;
              border-radius: 100%;
              background: #0E142A;
              flex-shrink: 0;

              img {
                position: absolute;
                width: 50%;
                height: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .description {
              width: calc(100% - 30px);
              width: 100%;

              .flex {
                display: flex;
                column-gap: 10px;
                align-items: center;

                .name {
                  overflow: hidden;
                  display: block;
                  margin: 0;
                  font-size: 0.95rem;
                }

              }

              .time {
                font-size: 0.7rem;
                color: #646464;
              }

              .newsarticle {
                font-size: 0.7rem;
                line-height: 1.7rem;
              }
            }
          }
        }
      }
    }
  }

  .recentnews {
    margin: 20px 0;

    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .news {
      display: grid;
      column-gap: 20px;
      row-gap: 40px;
      margin: 30px 0;
      justify-content: center;
      grid-template-columns: 90%;

      @media (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 550px) {
        grid-template-columns: 1fr;
      }

      a {
        text-decoration: none;
        color: #0E142A;

        .article-img {
          width: 100%;
          height: 150px;
          position: relative;
          overflow: hidden;
          border-radius: 6px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .description {
          padding: 10px 0px;
          width: 100%;

          .heading {
            overflow: hidden;
            font-size: 0.95rem;
            box-orient: vertical;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;

            @media (max-width: 840px) {
              line-clamp: 2;
              -webkit-line-clamp: 2;
            }
          }

          .time {
            font-size: 0.8rem;
            color: #646464;
            margin: 5px 0;
          }

          .newsarticle {
            font-size: 0.8rem;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;

            @media (max-width: 840px) {
              line-clamp: 3;
              -webkit-line-clamp: 3;
            }

            @media (max-width: 768px) {
              max-height: 65px;
            }
          }
        }
      }
    }
  }

  .error404 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      margin: 30px 0;
      width: 400px;

      @media (max-width: 550px) {
        width: 80%;
      }
    }

    a {
      padding: 4px 15px;
      text-decoration: none;
      border-radius: 5px;
      font-size: 0.9rem;
      border: 1px solid #E0291B;
      background: #E0291B;
      color: #fff;
      transition: .6s;

      &:hover {
        background: #fff;
        color: #E0291B;
      }
    }
  }
}
</style>
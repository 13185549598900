<template>
    <div class="content">
        <loader v-if="isLoading" />
        <div class="allprograms">
            <div class="header">
                <h2>RADIO PROGRAM</h2>
            </div>
            <div class="programs">
                <div class="program" v-for="program in dayPrograms" :key="program.id">
                    <div class="program" :class="{ activeProgram: program.id == activeProgramId }">
                        <div class="dot"></div>
                        <div class="time">
                            <label>{{ hm(program.startTime) }}</label>
                            <span>-</span>
                            <label>{{ hm(program.endTime) }}</label>
                        </div>
                        <h3><span>-</span>{{ program.title }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Admin-Programs',
    data() {
        return {
            isLoading: false,
            viewsearch: false,
            searched: '',
            allprograms: [],
            allnews: [],
            allpodcasts: [],
            alljobs: [],
            activeProgramId: null,
            timer: null,
        }
    },
    methods: {
        searchthis(searched) {
            this.viewsearch = true
            this.searched = searched
        },
        hm(time) {
            return time.split(':')[0] + ":" + time.split(':')[1]
        },
        checkActiveProgram(dayPrograms) {
            let vm = this
            let now = new Date()
            let nowTime = now.getHours() + ":" + now.getMinutes()
            let activeProgram = dayPrograms.filter(program => program.startTime <= nowTime && program.endTime >= nowTime)
            if (activeProgram.length > 0) {
                vm.activeProgramId = activeProgram[0].id
            } else {
                console.log('not found');
                return false
            }
        }
    },
    computed: {
        todayDay() {
            let day = new Date().getDay()
            if (day == 0) { return "Sunday" }
            else if (day == 1) { return "Monday" }
            else if (day == 2) { return "Tuesday" }
            else if (day == 3) { return "Wednesday" }
            else if (day == 4) { return "Thursday" }
            else if (day == 5) { return "Friday" }
            else if (day == 6) { return "Saturday" }
        },
        dayPrograms() {
            let vm = this
            return vm.allprograms.filter(program => program.day == vm.todayDay)
        }
    },
    mounted() {
        let vm = this
        vm.$getAllPrograms();
        vm.timer = setInterval(() => {
            vm.checkActiveProgram(vm.dayPrograms);
        }, 1000);
    },
    beforeUnmount() {
        clearInterval(this.timer);
    },


}
</script>
<style scoped lang="scss">
.content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 0 0 100px 0;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        width: 20px;
        background: #0E142A;
    }

    .search-enter-active,
    .search-leave-active {
        transition: .5s;
    }

    .search-enter-from,
    .search-leave-to {
        transform: translateY(20px);
        opacity: 0;
    }

    .allprograms {
        padding: 10px 40px;

        @media (max-width: 580px) {
            padding: 10px 10px;
        }

        .header {
            padding: 10px 30px;
            background: #D9D9D9;

            h2 {
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .programs {
            padding: 20px 30px;
            background: #EBEBEB;

            @media (max-width: 580px) {
                padding: 20px 20px;
            }

            .program {
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                .activeProgram {
                    color: #6c9716;

                    .dot {
                        background: #6c9716;
                    }
                }

                .dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    background: #BFBFBF;
                    margin-right: 20px;
                }

                .time {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    font-size: 0.9rem;

                    @media (max-width: 580px) {
                        font-size: 0.8rem;
                    }

                    span {
                        margin: 0 15px;
                    }
                }

                h3 {
                    display: flex;
                    align-items: center;
                    font-size: 0.9rem;
                    font-weight: 400;

                    @media (max-width: 580px) {
                        font-size: 0.8rem;
                    }

                    span {
                        margin: 0 15px;
                    }
                }
            }
        }
    }
}
</style>
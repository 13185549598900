<script>
export default{
    methods:{
        $getAllNews(){
            this.isLoading = true
            this.$store
            .dispatch("GET_ALL_ARTICLES")
            .then(resp =>{
                this.isLoading = false
                this.allnews = resp.data.data
            })
            .catch(err => console.log(err))
        },
        $getAllRecentNews(){
            this.isLoading = true
            this.$store
            .dispatch("GET_RECENT_ARTICLES")
            .then(resp =>{
                this.isLoading = false
                this.allnews = resp.data.data
            })
            .catch(err => console.log(err))
        },
        $getAllTimelinePodcasts(){
            this.$store
            .dispatch("GET_ALL_TIMELINE_PODCASTS")
            .then(resp =>{
                this.allpodcasts = resp.data.data
                console.log(resp.data.data)
                // this.allpodcasts.forEach(podcast => {
                //     podcast.isPlaying = false
                // })
            })
            .catch(err => console.log(err))
        },
        $getAllSiteContents(){
            this.$store
            .dispatch("GET_ALL_SITE_CONTENTS")
            .then(resp =>{
                this.siteContents = resp.data.data
                this.siteContents.forEach(content => {
                    content.options = false
                })
            })
            .catch(err => console.log(err))
        },
        $getAllRecentPodcasts(){
            this.isLoading = true
            this.$store
            .dispatch("GET_RECENT_PODCASTS")
            .then(resp =>{
                this.isLoading = false
                this.allpodcasts = resp.data.data
                this.allpodcasts.forEach(podcast => {
                    podcast.isPlaying = false
                })
            })
            .catch(err => console.log(err))
        },
        $getAllPodcasts(){
            this.isLoading = true
            this.$store
            .dispatch("GET_ALL_PODCASTS")
            .then(resp =>{
                this.isLoading = false
                this.allpodcasts = resp.data.data
                this.allpodcasts.forEach(podcast => {
                    podcast.isPlaying = false
                })
            })
            .catch(err => console.log(err))
        },
        $getAllJobs() {
            return [
                {
                    id: 1,
                    priceType: 'Fixed Price',
                    Level: 'Fixed Price',
                    Experience: 'Intermediate',
                    Budget: '$ 100',
                    title: 'Need help building ERP type of Web Application',
                    time: 'Today, 2:00 PM',
                    description: 'Gaana was Clean and Sexy designed by Tanmay Saxena. Connect with them on Dribbble Gaana was Clean and Sexy designed by Tanmay Saxena. Connect with them on Dribbble'
                },
            ]
        },
        $getAllPrograms(){
            this.$store
            .dispatch("GET_ALL_PROGRAMS")
            .then(resp =>{
                let vm = this
                let response = resp.data.data
                let day = new Date().getDay()
                vm.allprograms = resp.data.data
            })
            .catch(err => console.log(err))
        },
        $Checklog() {
            let vm = this;
            let user = localStorage.getItem("loggeduser");
            if (user) {
                let processedUser = JSON.parse(user);
                // console.log(processedUser)
                if (processedUser.user.role == 'SuperAdmin') {
                    vm.$router.push({ path: "/admin" })
                } else if (processedUser.user.role == 'Admin') {
                    vm.$router.push({ path: "/admin" })
                }
            }else{
                vm.$router.push({ path: "/admin/login" })
            }
        },
        $delete(entity, type) {
            let vm = this
            vm.isDelete = true
            vm.deleteId = entity.id
            entity.options = false
            vm.deleteType = type
        },
        $searching(search){
            let vm = this
            vm.$store.state.searchText = search;
            vm.$store
            .dispatch("SEARCH", { query: search })
            .then((response) => {
                // this.isLoading = false
                vm.$store.state.searchResults = response.data.data;
            })
            .catch(response => {
                // this.isLoading = false;  
            });
        }
    }
}
</script>
export const API_BASE_URL = process.env.NODE_ENV === 'production' ? "https://api.cityradio.rw/api/v1" : "http://localhost:3000/api/v1";
export const ASSETS_BASE_URL = "https://cagura-assets.b-cdn.net/cityradio/";
export const SOCKET_URL = "";
// auth
export const ACCOUNT_LOGIN_URL = "/auth/login";
// users
export const GET_ALL_USERS_URL = '/user';
export const CREATE_USER_URL = '/user/create';
export const UPDATE_USER_URL = '/user/update/';
export const DELETE_USER_URL = '/user/delete/';
// programs
export const GET_ALL_PROGRAMS_URL = '/programs';
export const CREATE_PROGRAM_URL = '/programs';
export const UPDATE_PROGRAM_URL = '/programs/';
export const DELETE_PROGRAM_URL = '/programs/';
// podcast
export const GET_ALL_PODCASTS_URL = '/podcast';
export const GET_ALL_TIMELINE_PODCASTS_URL = '/podcast/timeline';
export const GET_RECENT_PODCASTS_URL = '/podcast/recent';
export const CREATE_PODCAST_URL = '/podcast';
export const UPDATE_PODCAST_URL = '/podcast/';
export const DELETE_PODCAST_URL = '/podcast/';
// News
// News Category
export const GET_ALL_CATEGORIES_URL = '/news/categories'
export const GET_SINGLE_CATEGORY_URL = '/news/articles/category/';
export const CREATE_CATEGORY_URL = '/news/categories';
export const UPDATE_CATEGORY_URL = '/news/categories/';
export const DELETE_CATEGORY_URL = '/news/categories/';
// News Articles
export const GET_ALL_ARTICLES_URL = '/news/articles'
export const GET_RECENT_ARTICLES_URL = '/news/recent/articles';
export const CREATE_ARTICLE_URL = '/news/articles';
export const UPDATE_ARTICLE_URL = '/news/articles/';
export const DELETE_ARTICLE_URL = '/news/articles/';
// News Site 
export const GET_ALL_SITE_CONTENTS_URL = '/site-content/slide'
export const CREATE_SITE_CONTENT_URL = '/site-content';
export const UPDATE_SITE_CONTENT_URL = '/site-content/';
export const DELETE_SITE_CONTENT_URL = '/site-content/';
// Site comments
export const GET_ALL_COMMENTS_URL = '/news/comments/article/'
export const CREATE_COMMENT_URL = '/news/comments';
export const UPDATE_COMMENT_URL = '/news/comments/visibility/';
export const DELETE_COMMENT_URL = '/news/comments/';
// upload 
export const CREATE_UPLOAD_URL = '/upload'
export const DELETE_UPLOADED_FILE_URL = '/upload/delete'
// Contact
export const SEND_MESSAGE_URL = '/contact/us'
// search
export const SEARCH_URL = '/search'
<template>
    <div class="chat-message-item">
        <div :class="{ 'left-message-bubble': type == 'left', 'right-message-bubble': type == 'right' }">
            <div class="message-wrapper">
                <div class="text-message">
                    Hello There, Twakubuze hano kuri Radio
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'chat-message-item',
    props: {
        type: {
            type: String,
            default: "left"
        }
    },
    methods: {

    },
}
</script>
<style scoped lang="scss">
.chat-message-item {
    width: 100%;
    display: flex;

    .right-message-bubble {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .message-wrapper {
            max-width: 70%;
            padding-right: 20px;

            .text-message {
                background: #01c552;
                color: #fff;
                padding: 10px 20px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;

            }
        }

    }

    .left-message-bubble {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .message-wrapper {
            max-width: 70%;
            padding-left: 20px;

            .text-message {
                background: #e4e4e4;
                color: #000000;
                padding: 10px 20px;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
                border-top-left-radius: 20px;

            }
        }
    }
}
</style>


// Categories
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_SINGLE_CATEGORY = 'GET_SINGLE_CATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
// Articles
export const GET_ALL_ARTICLES = 'GET_ALL_ARTICLES'
export const GET_RECENT_ARTICLES = 'GET_RECENT_ARTICLES'
export const CREATE_ARTICLE = 'CREATE_ARTICLE';
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
// Comments 
export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS'
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
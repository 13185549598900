<template>
    <div class="page-wrapper">
        <form @submit.prevent="login()" class="form-container">
            <div class="wlogo">
                <div class="logo">
                    <img src="@/assets/logo.svg" alt="" />
                </div>
                <div class="name">
                    <h1>ADMIN LOG IN</h1>
                </div>
            </div>
            <div class="logdetails">
                <label>Email</label>
                <input type="email" v-model="form.email" />
                <label>Password</label>
                <input type="password" v-model="form.password" />
                <button class="logbtn">
                    <van-button v-if="submitloading" loading />
                    Log in
                </button>
            </div>
        </form>
    </div>
</template>
  
<script>
export default {
    name: 'Admin-Login',
    data() {
        return {
            form: {
                email: "",
                password: "",
            },
            submitloading: false,
        }
    },
    methods: {
        login() {
            let vm = this;
            vm.submitloading = true;
            vm.$store
                .dispatch("ACCOUNT_LOGIN", vm.form)
                .then((response) => {
                    vm.submitloading = false;
                    localStorage.setItem("loggeduser", JSON.stringify(response.data));
                    if (response.data.user) {
                        if (response.data.user.role == 'SuperAdmin') {
                            vm.$store.dispatch("setAccessToken", response.data.accessToken);
                            vm.$router.push({ path: "/admin" })
                        }
                    } else {
                        localStorage.removeItem("loggeduser")
                        vm.$store.dispatch("removeAccessToken");
                        vm.$notify({
                            type: 'error',
                            title: 'Important Message',
                            text: response.data.message
                        })
                    }
                })
                .catch((err) => {
                    vm.submitloading = false;
                    console.log(err)
                    vm.$notify({
                        type: 'error',
                        title: 'Important Message',
                        text: err.data.message
                    })
                });
        },
    },
    mounted() {
        let vm = this
        vm.$Checklog()
    }
}
</script>
<style scoped lang="scss">
.page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    form {
        @media (max-width: 500px) {
            width: 80%;
        }

        &.form-container {
            width: 450px;
            border-radius: 20px;
            padding: 15px 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            @media (max-width: 500px) {
                width: 80%;
            }

            @media (max-width: 450px) {
                padding: 15px 20px;
            }

            @media (max-width: 400px) {
                width: 95%;
            }

            .wlogo {
                display: flex;
                align-items: center;
                flex-direction: column;
                margin: 5px auto;
            }

            .logo {
                width: 90px;
                height: 90px;
                position: relative;

                @media (max-width: 450px) {
                    width: 80px;
                    height: 80px;
                }

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .name {
                h1 {
                    font-weight: 600;
                    font-size: 1.1rem;
                    color: #0E142A;
                }
            }
        }

        .logdetails {
            width: 100%;
            margin-top: 7px;
            margin-bottom: 30px;

            label {
                font-size: 0.9rem;
                color: #0E142A;
                display: block;
                margin: 5px 0;
                text-align: left;
            }

            input,
            button {
                background: transparent;
                border: none;
                border-bottom: 1px solid #0e142a60;
                color: #0E142A;
                padding: 7px 15px;
                outline: none;
                width: 100%;
                display: block;
                font-size: 0.8rem;
            }

            .logbtn {
                border: 1px solid #0e142a60;
                border-radius: 7px;
                position: relative;
                overflow: hidden;
                border: none;
                padding: 8px 15px;
                margin: 20px 0 0px 0;
                background: #0E142A;
                color: #fff;
                font-weight: 600;
                cursor: pointer;
                position: relative;

                .van-button {
                    background: #0E142A;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    z-index: 5;
                }
            }
        }
    }
}

.van-loading__circular {
    display: block;
    width: 100%;
    height: 100%;
}
</style>